import React from 'react';
import { EditButton, ShowButton, useEditContext, useResourceContext } from 'react-admin';

import { FF_SSO_RESET_PASSWORD } from '@hisports/common/featureFlags';

import { useFlag } from '../../http';
import { TopToolbar } from '../../common/cards/Card';
import { isAuthorized } from '../../common/Authorize';

import { SMAResetPasswordButton } from './SMAResetPassword';

const PLAY_ENVIRONMENT = process.env.PLAY_ENVIRONMENT || '';

export const AccountActions = ({ hasEdit, hasShow, ...props }) => {
  const resource = useResourceContext(props);
  const isEnabled = useFlag()
  const { basePath, record: account } = useEditContext(props);

  const canEditResource = isAuthorized(account, resource, 'edit');
  const canShowResource = isAuthorized(account, resource, 'show');

  const isSSOAccount = !!account?.externalId;

  return <TopToolbar>
    {hasEdit && canEditResource && (isSSOAccount ? <SMAResetPasswordButton disabled={!isEnabled(FF_SSO_RESET_PASSWORD) || PLAY_ENVIRONMENT == 'staging'} /> : <EditButton basePath={basePath} resource={resource} record={account} label="ra.action.edit" />)}
    {hasShow && canShowResource && <ShowButton basePath={basePath} resource={resource} record={account} />}
  </TopToolbar>
}
