import React from 'react';
import { useTranslate } from 'react-admin';
import { Alert } from '@material-ui/lab';
import { Search } from '@material-ui/icons';

import { List, Show, Create, Edit, Pagination } from '../../common/ra';
import ResourceTitle from '../../common/ResourceTitle';
import { SMAChip } from '../../common/chips/SMAChip';

import { AccountGrid } from './AccountGrid';
import { AccountFilters } from './AccountFilters';
import { AccountShowLayout } from './AccountShow';
import { AccountForm } from './AccountForm';
import { AccountActions } from './AccountActions';

const AccountPagination = ({ total, ...props }) => {
  const translate = useTranslate();
  if (total < 0) return <Alert severity="info" icon={<Search />}>{translate('resources.accounts.alerts.enter_email_above')}</Alert>
  return <Pagination total={total} {...props} />
}

export const AccountList = props =>
  <List {...props}
    filters={<AccountFilters />}
    sort={{
      field: 'email',
      order: 'ASC'
    }}
    pagination={<AccountPagination />}
  >
    <AccountGrid rowClick="show" />
  </List>

export const AccountShow = props =>
  <Show
    title={<ResourceTitle title={record => record.email} chip={record => record?.externalId && <SMAChip />} />}
    actions={<AccountActions />}
    {...props}
  >
    <AccountShowLayout />
  </Show>

export const AccountCreate = props => {
  const translate = useTranslate();
  return <Create
    title={<ResourceTitle title={record => translate('resources.accounts.labels.my')} />}
    {...props}
  >
    <AccountForm redirect="show" />
  </Create>
}

export const AccountEdit = props => {
  const translate = useTranslate();
  return <Edit
    title={<ResourceTitle title={record => translate('ra.page.editing', { name: record.email })} />}
    {...props}
  >
    <AccountForm redirect="show" />
  </Edit>
}
