export const SCOPE_DESCRIPTIONS = {
  'assigning:assign': 'Assign games, manage attributes, transactions, claims, pay periods, send announcements',
  'assigning:availability': 'Manage official availability',
  'assigning:lists': 'Manage official lists',
  'assigning:manage': 'Manage assigning settings, rules, fees, overrides, attributes, transactions, claims, pay periods, send announcements',
  'assigning:qualifications': 'Manage official qualifications',
  'assigning:view': 'View anything related to assigning',

  'cms:manage': 'Manage content for hisports public sites (legacy)',
  'cms:public': 'View content for hisports public sites (legacy)',

  'discipline:manage': 'Manage anything related to suspension disciplines',
  'discipline:trigger': 'Trigger the discipline process from a suspension',
  'discipline:view': 'View anything related to suspension disciplines',

  'internal': 'Spordle internal use only',

  'leagues:manage': 'Manage organizations, organization settings, organization members, groups, analyze schedules, penalties, suspensions, external providers, organization notifications',
  'leagues:public': 'View league information on public websites',
  'leagues:view': 'View anything related to leagues',

  'participants:accounts': 'Manage accounts and linked identities',
  'participants:invite': 'Send invites to participants',
  'participants:manage': 'Manage participant information',
  'participants:permissions': 'Manage roles/permissions',
  'participants:view': 'View anything related to participants',

  'profiles:addresses': 'Manage addresses',
  'profiles:manage': 'Manage profiles',
  'profiles:notifications': 'Manage notifications',
  'profiles:public': 'View profiles on public websites',

  'scheduling:approve': 'Approve games and draft games',
  'scheduling:availability': 'Manage availability and time slots',
  'scheduling:drafts': 'Schedule shared draft games',
  'scheduling:games': 'Schedule games',
  'scheduling:manage': 'Manage schedules, teams, games, drafts and settings',
  'scheduling:practices': 'Manage practices',
  'scheduling:public': 'View schedules on public websites',
  'scheduling:status': 'Change the game status through the bulk action or change status form',
  'scheduling:view': 'View anything related to scheduling',

  'scoresheets:events': 'View audit logs',
  'scoresheets:lineups': 'Manage scoresheet lineup',
  'scoresheets:manage': 'Manage full scoresheet and certify',
  'scoresheets:view': 'View anything related to scoresheets',

  'stats:public': 'View stats on public websites',
  'stats:reports': 'View reports based on role context',
  'stats:view': 'View schedule/game stats and box scores',

  'suspensions:manage': 'Manage automatic and manual suspensions',
  'suspensions:view': 'View anything related to suspensions',

  'teams:activities': 'Manage team activities',
  'teams:manage': 'Manage anything related to teams',
  'teams:public': 'View team information on public websites',
  'teams:roster': 'Manage team rosters',
  'teams:settings': 'Manage team settings',
  'teams:view': 'View anything related to teams',
};
