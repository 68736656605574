import React from 'react';
import { useRecordContext } from 'react-admin';
import { Card, List } from '@material-ui/core';

import { isAuthorized } from '../../../common/Authorize';

import AssignRuleApplyModal from '../../assignRules/AssignRuleApplyModal';
import OfficeMembersModal from '../../officemembers/OfficeMembersModal';
import { AnalyzeMenuItem } from '../../schedules/AnalyzeDialog';

export default props => {
  const office = useRecordContext(props);

  const canResetRules = isAuthorized(office, 'assignRules', 'edit')
  const canShowMembers = isAuthorized(office, 'officemembers', 'list') && ['League', 'Tournament', 'Cup', 'Zone'].includes(office.type)
  const showAnalyze = ['League', 'Tournament', 'Cup', 'Association'].includes(office?.type);

  const actions = [
    canShowMembers && <OfficeMembersModal key="members" onClick={() => true} />,
    showAnalyze && <AnalyzeMenuItem key="analyze" officeId={office?.id} onClick={() => true} />,
    canResetRules && <AssignRuleApplyModal key="reset" type="assignOffices" officeId={office?.id} onClick={() => true} />,
    canResetRules && <AssignRuleApplyModal key="apply" type="scheduleOffices" officeId={office?.id} onClick={() => true} />
  ].filter(Boolean);

  if (!actions.length) return null;

  return <Card>
    <List>
      {actions}
    </List>
  </Card>
}
