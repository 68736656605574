import React from 'react';
import { ChipField, ReferenceArrayInput, ReferenceInput, SelectArrayInput, useTranslate } from "react-admin";
import { makeStyles } from '@material-ui/core';
import { useFormState } from 'react-final-form';

import { dedupeBy } from '@hisports/parsers';

import ChipSelectInput from '../../common/inputs/ChipSelectInput';

const useStyles = makeStyles(theme => ({
  selectArrayInput: {
    // add half of the label's line-height top, + bottom for v-alignment
    paddingBlock: 'calc(1rem * .75 / 2)',

    '& .MuiFormHelperText-root:empty': {
      margin: 0,
    }
  },
}))

const validate = (value, translate) => {
  if (typeof value === 'undefined') return translate('ra.validation.required')
}

const optionText = group => group.name;

const ChoiceInput = ({ choices, showNone, allowEmpty = showNone, children, ...props }) => {
  const dedupedChoices = dedupeBy('id', choices);
  if (!dedupedChoices.length) return null;
  return React.cloneElement(children, { ...props, choices: dedupedChoices, allowEmpty })
}

export const PoolInput = ({ select = false, multiple, showNone, filter = {}, helperText, defaultValue, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { values } = useFormState();

  const scheduleId = props?.scheduleId || values?.scheduleId;
  if (!scheduleId) return null;

  let input = null;
  if (select && multiple) {
    input = <SelectArrayInput optionText={optionText} allowEmpty={showNone} helperText={helperText} {...props}>
      <ChipField source={optionText} />
    </SelectArrayInput>
  } else {
    input = <ChipSelectInput multiple={multiple} optionText={optionText} showNone={showNone} helperText={helperText} emptyText="resources.pools.messages.all_pools" {...props} />
  }

  const Reference = multiple ? ReferenceArrayInput : ReferenceInput;
  return <div className={select && multiple ? classes.selectArrayInput : null}>
    <Reference
      {...props}
      reference="pools"
      filter={{ scheduleId, ...filter }}
      filterToQuery={() => null}
      sort={{
        field: 'name',
        order: 'ASC'
      }}
      perPage={9999}
      defaultValue={defaultValue}
      validate={showNone ? (value) => validate(value, translate) : null}
      margin="none"
    >
      <ChoiceInput showNone={showNone}>
        {input}
      </ChoiceInput>
    </Reference>
  </div>
}
