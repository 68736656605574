import React, { useMemo, useState } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, Typography, Accordion, AccordionSummary, AccordionDetails, Tabs, Tab, TextField, IconButton, makeStyles } from '@material-ui/core';
import { Accessibility, ArrowDropDown, BrightnessAuto, Close, ControlCamera } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import { translateApiProperty } from '@hisports/common';
import { capitalize } from '@hisports/common/src/lodash';
import { SCOPE_DESCRIPTIONS } from '@hisports/common/src/scope-descriptions';

import { useHttpClient } from '../../http';

const AUTOMATIC_ROLES = [
  {
    name: 'participant',
    i18n: {
      en: {
        name: 'Participant',
        description: 'Automatic to all participants, it allows participants to manage their own profile',
      },
      fr: {
        name: 'Participant',
        description: 'Automatique pour tous les participants, il permet aux participants de gérer leur propre profil',
      },
    },
    targetType: 'Participant',
    scopes: [
      'profiles:manage',
      'profiles:addresses',
      'profiles:notifications'
    ],
  },
  {
    name: 'official',
    i18n: {
      en: {
        name: 'Official',
        description: 'Automatic with an official qualification, it allows to be assigned and officiate games',
      },
      fr: {
        name: 'Officiel',
        description: "Automatique avec une qualification d'officiel, cela permet d’être assigné et d’arbitrer des matchs.",
      },
    },
    targetType: 'Participant',
    scopes: [
      'assigning:availability',
      'assigning:official',
    ],
  },
  {
    name: 'scorekeeper',
    i18n: {
      en: {
        name: 'Scorekeeper',
        description: 'Automatic with a scorekeeper official qualification, it allows to be assigned and manage scoresheets',
      },
      fr: {
        name: 'Marqueur',
        description: "Automatique avec une qualification d’officiel marqueur, cela permet d’être assigné et de gérer les feuilles de match",
      },
    },
    targetType: 'Participant',
    scopes: [
      'assigning:availability',
      'assigning:official',
      'scoresheets:view',
      'scoresheets:lineups',
      'scoresheets:manage'
    ],
  },
  {
    name: 'coach',
    i18n: {
      en: {
        name: 'Team Staff',
        description: 'Automatic when rostered as a team staff on a team, it allows to manage anything related to the team',
      },
      fr: {
        name: "Personnel d'équipe",
        description: "Automatique une fois inscrit en tant que personnel d'équipe, cela permet de gérer tout ce qui concerne l'équipe.",
      }
    },
    targetType: 'Team',
    scopes: [
      'profiles:manage',
      'teams:roster',
      'teams:activities',
      'scheduling:view',
      'scheduling:approve',
      'assigning:view',
      'scoresheets:lineups',
      'scoresheets:view',
      'stats:view',
      'stats:reports',
      'suspensions:view',
    ],
  },
  {
    name: 'player',
    i18n: {
      en: {
        name: 'Player',
        description: 'Automatic when rostered as a player on a team, it allows to view team information and upcoming games',
      },
      fr: {
        name: 'Joueur',
        description: "Automatique une fois inscrit en tant que joueur sur une équipe, cela permet de consulter les informations de l'équipe et les matchs à venir",
      }
    },
    targetType: 'Team',
    scopes: [
      'teams:view',
      'scheduling:view',
      'scoresheets:view',
      'suspensions:view',
    ],
  }
]

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  group: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  stack: {
    display: 'flex',
    flexDirection: 'column',
  }
}))

const Roles = ({ roles, search }) => {
  const translate = useTranslate();
  const locale = useLocale();
  const classes = useStyles();

  const sortedRoles = useMemo(() => {
    return [
      ...AUTOMATIC_ROLES,
      ...(roles?.sort((a, b) => translate(`resources.permissions.values.targetType.${a.targetType}`).localeCompare(translate(`resources.permissions.values.targetType.${b.targetType}`))) || [])
    ]
  }, [roles, translate])

  return <div>
    {sortedRoles?.map(role => {
      const targetType = translate(`resources.permissions.values.targetType.${role.targetType}`);
      const name = capitalize(translateApiProperty(role, 'name', locale));
      const description = translateApiProperty(role, 'description', locale);
      const secondaryText = `${targetType}${description ? ` - ${description}` : ''}`;

      if (search && !name?.toLowerCase().includes(search.toLowerCase()) && !description?.toLowerCase().includes(search.toLowerCase())) return null;
      return (
        <Accordion key={role.id || role.name}>
          <AccordionSummary expandIcon={<ArrowDropDown />}>
            <div className={classes.group}>
              {role.id ? <Accessibility fontSize="small" /> : <BrightnessAuto fontSize="small" />}
              <div className={classes.stack}>
                <Typography component="span">{name}{!role?.id ? ` (${translate('components.roles_and_permissions.labels.automatic')})` : ''}</Typography>
                <Typography color="textSecondary" variant="body2">{secondaryText}</Typography>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="div">
              {translate('components.roles_and_permissions.labels.scopes')}:
              <ul>
                {role?.scopes.sort((a, b) => a.localeCompare(b)).map((scope) => (
                  <li key={scope} style={{ marginBottom: 16 }}>
                    {scope} <Typography color="textSecondary" variant="body2">{SCOPE_DESCRIPTIONS[scope]}</Typography>
                  </li>
                ))}
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
      );
    })}
  </div>
}

const Scopes = ({ search, roles = [] }) => {
  const translate = useTranslate();
  const locale = useLocale();
  const classes = useStyles();

  return <div>
    {Object.entries(SCOPE_DESCRIPTIONS).map(([scope, description]) => {
      if (search && !scope.toLowerCase().includes(search.toLowerCase()) && !description.toLowerCase().includes(search.toLowerCase())) return null;

      const scopeRoles = roles.filter(role => role.scopes.includes(scope));

      return (
        <Accordion key={scope}>
          <AccordionSummary expandIcon={<ArrowDropDown />}>
            <div className={classes.group}>
              <ControlCamera fontSize="small" />
              <div className={classes.stack}>
                <Typography component="span">{scope}</Typography>
                <Typography color="textSecondary" variant="body2">{SCOPE_DESCRIPTIONS[scope]}</Typography>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="div">
              {translate('resources.permissions.labels.card.title')}:
              <ul>
                {scopeRoles.map((role) => {
                  const targetType = translate(`resources.permissions.values.targetType.${role.targetType}`);
                  const name = capitalize(translateApiProperty(role, 'name', locale));
                  return <li key={role?.id || role?.name}>
                    {name} ({targetType})
                  </li>
                })}
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
      )
    })}
  </div>
}

export const RolesAndPermissionsDialog = ({ open, onClose }) => {
  const { data: roles } = useHttpClient('/roles');
  const translate = useTranslate();
  const [tabIndex, setTabIndex] = useState(0);
  const [search, setSearch] = useState('');
  const classes = useStyles();

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        style: {
          height: '90vh',
          maxHeight: '90vh',
        },
      }}
    >
      <DialogTitle> {translate('components.roles_and_permissions.title')}</DialogTitle>
      <div className={classes.closeButton}>
        <IconButton onClick={onClose}>
          <Close fontSize="small" />
        </IconButton>
      </div>
      <DialogContent>
        <Alert className={classes.alert} severity="info">{translate('components.roles_and_permissions.alerts.account_permissions')}</Alert>
        <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
          <Tab label={translate('resources.permissions.labels.card.title')} />
          <Tab label={translate('components.roles_and_permissions.labels.scopes')} />
        </Tabs>
        <TextField
          label={translate('ra.action.search')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          variant="outlined"
          fullWidth
          style={{ marginBlock: 16 }}
        />
        {tabIndex === 0 && (
          <Roles roles={roles} search={search} />
        )}
        {tabIndex === 1 && (
          <Scopes roles={roles} search={search} />
        )}
      </DialogContent>
    </Dialog>
  );
};
