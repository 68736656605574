import React from 'react';
import { NumberInput, SimpleForm, useTranslate } from 'react-admin';
import { Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import { useFormState } from 'react-final-form';

import Toolbar from '../../common/ra/Toolbar';
import SwitchInput from '../../common/inputs/SwitchInput'
import { EnumInput } from '../../common/inputs/EnumInputs';
import { FieldDependency } from '../../common/FieldDependency';

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const validate = values => {
  const errors = {};

  if (values.visibleDays % 1 > 0) {
    errors.visibleDays = 'ra.validation.invalid_number';
  } else if (values.visibleDays !== null && values.visibleDays < 0) {
    errors.visibleDays = 'ra.validation.positive_leave_empty'
  }

  if (values.dailyLimit !== null && values.dailyLimit <= 0) {
    errors.dailyLimit = 'ra.validation.greater_than_zero_leave_empty_limit'
  }

  if (values.declineHours !== null && values.declineHours <= 0) {
    errors.declineHours = 'ra.validation.greater_than_zero_leave_empty'
  }

  if (values.claimableDriver && values.driverAmount == null) errors.driverAmount = 'ra.validation.required';
  if (values.claimablePassenger && values.passengerAmount == null) errors.passengerAmount = 'ra.validation.required';
  if (values.claimableBreakfast && values.breakfastAmount == null) errors.breakfastAmount = 'ra.validation.required';
  if (values.claimableLunch && values.lunchAmount == null) errors.lunchAmount = 'ra.validation.required';
  if (values.claimableDinner && values.dinnerAmount == null) errors.dinnerAmount = 'ra.validation.required';

  if (values.driverExcludedDistance != null) {
    if (values.driverExcludedDistance <= 0) {
      errors.driverExcludedDistance = 'ra.validation.greater_than_zero'
    } else if (!Number.isInteger(values.driverExcludedDistance)) {
      errors.driverExcludedDistance = 'ra.validation.whole'
    }
  }
  if (values.passengerExcludedDistance != null) {
    if (values.passengerExcludedDistance <= 0) {
      errors.passengerExcludedDistance = 'ra.validation.greater_than_zero'
    } else if (!Number.isInteger(values.passengerExcludedDistance)) {
      errors.passengerExcludedDistance = 'ra.validation.whole'
    }
  }

  return errors;
}

const VisibleDaysOptions = props => {
  const translate = useTranslate();
  const { values } = useFormState();

  const { visibleDaysEnabled } = values;
  if (!visibleDaysEnabled) return null;

  return <NumberInput
    source="visibleDays"
    label={translate('resources.officeAssignSettings.fields.visibleDays')}
    helperText="resources.officeAssignSettings.helpers.visibleDays"
    {...inputProps}
  />
}

const RequestOptions = props => {
  const translate = useTranslate();
  const { values } = useFormState();

  const { visibleDaysEnabled } = values;

  return <>
    {!visibleDaysEnabled && <Alert severity="warning" fullWidth>{translate("resources.officeAssignSettings.alerts.visibleDaysEnabled")}</Alert>}
    <SwitchInput
      source="requestsEnabled"
      label={translate("resources.officeAssignSettings.fields.requestsEnabled")}
      helperText="resources.officeAssignSettings.helpers.requestsEnabled"
      disabled={!visibleDaysEnabled}
      {...inputProps}
    />
  </>
}

const DeclineOptions = inputProps => {
  const { values } = useFormState();

  const { declineEnabled } = values;
  if (!declineEnabled) return null;

  return <>
    <SwitchInput
      source="removeAfterDecline"
      helperText="resources.officeAssignSettings.helpers.removeAfterDecline"
      {...inputProps}
    />
    <SwitchInput
      source="declineConfirmedDisabled"
      helperText="resources.officeAssignSettings.helpers.declineConfirmedDisabled"
      {...inputProps}
    />
    <SwitchInput
      source="declineReason"
      helperText="resources.officeAssignSettings.helpers.declineReason"
      {...inputProps}
    />
    <NumberInput
      source="declineHours"
      helperText="resources.officeAssignSettings.helpers.declineHours"
      {...inputProps}
    />
  </>
}

const AssignmentVisibilityEnumInput = props =>
  <EnumInput {...props} radio choices={[
    { id: 'Visible', name: 'resources.officeAssignSettings.values.assignmentVisibility.Visible' },
    { id: 'Status', name: 'resources.officeAssignSettings.values.assignmentVisibility.Status' },
    { id: 'Hidden', name: 'resources.officeAssignSettings.values.assignmentVisibility.Hidden' },
  ]} />

export default props => {
  const translate = useTranslate();
  return <SimpleForm toolbar={<Toolbar />} validate={validate} {...props}>
    <Typography variant="subtitle2" gutterBottom>{translate('resources.officeAssignSettings.labels.general')}</Typography>
    <SwitchInput
      source="visibleDaysEnabled"
      helperText="resources.officeAssignSettings.helpers.visibleDaysEnabled"
      {...inputProps}
    />
    <VisibleDaysOptions />
    <NumberInput
      source="dailyLimit"
      helperText="resources.officeAssignSettings.helpers.dailyLimit"
      min={1}
      {...inputProps}
    />

    <AssignmentVisibilityEnumInput
      source="assignmentVisibility"
      helperText="resources.officeAssignSettings.helpers.assignmentVisibility"
      {...inputProps}
    />

    <SwitchInput
      source="assignerVisible"
      helperText="resources.officeAssignSettings.helpers.assignerVisible"
      {...inputProps}
    />

    <SwitchInput
      source="supervisorVisible"
      helperText="resources.officeAssignSettings.helpers.supervisorVisible"
      {...inputProps}
    />

    <SwitchInput
      source="removeAfterRescheduled"
      helperText="resources.officeAssignSettings.helpers.removeAfterRescheduled"
      {...inputProps}
    />
    <FieldDependency fieldSource="removeAfterRescheduled" test={value => !!value}>
      <SwitchInput
        source="removeOnDateChange"
        label="resources.officeAssignSettings.fields.removeOnDateChange"
        helperText="resources.officeAssignSettings.helpers.removeOnDateChange"
        {...inputProps}
      />
    </FieldDependency>

    <SwitchInput
      source="enforceConsecutive"
      helperText="resources.officeAssignSettings.helpers.enforceConsecutive"
      {...inputProps}
    />

    <SwitchInput
      source="effectiveOfficialsList"
      helperText="resources.officeAssignSettings.helpers.effectiveOfficialsList"
      {...inputProps}
    />

    <br />
    <Typography variant="subtitle2" gutterBottom>{translate('resources.officeAssignSettings.labels.requests')}</Typography>
    <RequestOptions />

    <br />
    <Typography variant="subtitle2" gutterBottom>{translate('resources.officeAssignSettings.labels.declines')}</Typography>
    <SwitchInput
      source="declineEnabled"
      helperText="resources.officeAssignSettings.helpers.declineEnabled"
      {...inputProps}
    />
    <DeclineOptions {...inputProps} />

    <br />
    <Typography variant="subtitle2" gutterBottom>{translate('resources.officeAssignSettings.labels.claims')}</Typography>
    <SwitchInput
      source="allowEarlyClaims"
      helperText="resources.officeAssignSettings.helpers.allowEarlyClaims"
      {...inputProps}
    />
  </SimpleForm>
}
