import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

export const PoolField = ({ variant = 'body2', ...props }) =>
  <ReferenceField basePath="/pools" resource="pools" reference="pools" link={false} allowEmpty {...props} >
    <TextField source="name" variant={variant} />
  </ReferenceField>

PoolField.defaultProps = {
  addLabel: true,
}
