import React from 'react';
import { FunctionField, useListContext, useLocale, useTranslate } from 'react-admin';
import { Clear, DoneAll, Inbox } from '@material-ui/icons';
import { Tooltip, Typography, useTheme } from '@material-ui/core';
import moment from 'moment';

import { PopoverTextField } from '../../common/fields/PopoverTextField';
import { hasAnyScope } from '../../common/Authorize';
import { usePermissions } from '../../http';
import { formatCurrency, formatDistance } from '../../locale';

import { OfficeField } from '../offices/OfficeField';
import { ParticipantField } from '../participants/ParticipantField';
import { GameField } from '../games/GameField';

import { ClaimDatagrid } from './ClaimModal';
import { EnumField } from '../../common/inputs/EnumInputs';

export const isDistance = (type) => ['Travel', 'Driver', 'Passenger'].includes(type);
export const isPerDiemAndExpense = (type) => ['Per Diem', 'Expense'].includes(type);

export const isAssigner = (permissions, officeId) => {
  return officeId && permissions && permissions.some(({ roleType, scopes = [], officeIds = [], inherited }) =>
    (roleType === 'System') ||
    (roleType === 'Office' && inherited === false && hasAnyScope(scopes, ['assigning:manage', 'assigning:assign']) && officeIds.includes(officeId))
  )
}

const AmountField = props => {
  const locale = useLocale();
  return <FunctionField
    render={({ type, distance, amount }) => {
      if (!type || !amount) return;
      if (distance && isDistance(type)) {
        return <>
          <Typography variant="body2" display="inline">{formatCurrency(amount, locale, true)}</Typography>
          <Typography variant="body2" display="inline" color="textSecondary"> ({formatDistance(distance)})</Typography>
        </>
      }
      return formatCurrency(amount, locale, true)
    }}
    {...props}
  />
}

const StatusIcon = ({ status, style, ...props }) => {
  const theme = useTheme();
  const translate = useTranslate()
  const toolTipTitle = translate(`resources.officialClaims.values.status.${status}`);
  if (status === 'Approved') {
    return <Tooltip title={toolTipTitle}>
      <DoneAll fontSize="small" htmlColor={theme.palette.success.main} style={style} />
    </Tooltip>
  }
  if (status === 'Declined') {
    return <Tooltip title={toolTipTitle}>
      <Clear fontSize="small" color="error" style={style} />
    </Tooltip>
  }
  return <Tooltip title={toolTipTitle}>
    <Inbox fontSize="small" style={style} />
  </Tooltip>
}

const StatusField = props => {
  const translate = useTranslate()
  return <FunctionField
    render={({ status }) => {
      const statusText = translate(`resources.officialClaims.values.status.${status}`)

      return <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        {statusText}
        <StatusIcon status={status} fontSize="default" style={{ marginLeft: 4 }} />
      </div>
    }}
    {...props}
  />
}

const AmountAndNoteField = props =>
  <FunctionField
    render={({ amount, distance, type }) => {
      const marginLeft = (amount && isPerDiemAndExpense(type)) || (distance && isDistance(type)) ? '4px' : undefined;
      return <div style={{ display: 'inline-flex' }}>
        <AmountField source="amount" />
        <PopoverTextField source="note" style={{ marginLeft }} />
      </div>
    }}
    {...props}
  />

export const ClaimsGrid = ({ hideParticipant = false, hideGame = false, hideOffice = false, ...props }) => {
  const permissions = usePermissions();
  const { data: claims } = useListContext()

  const isRowSelectable = (claimId) => {
    const { officeId, status } = claims?.[claimId] || {};
    return isAssigner(permissions, officeId) || status === 'Pending';
  }

  return <ClaimDatagrid size="medium" rowOptions={{ isRowSelectable }}{...props}>
    {!hideParticipant && <ParticipantField source="participantId" />}
    {!hideGame && <GameField source="gameId" includeDate="inline" includeTime includeSurface="full" />}
    {!hideOffice && <OfficeField source="officeId" />}
    <EnumField source="type" />
    <AmountAndNoteField source="amount" />
    <StatusField source="status" />
    <FunctionField source="createdAt" render={claim => moment(claim.createdAt).format('LLL')} />
  </ClaimDatagrid>
}
