import React from 'react';
import { Datagrid, FunctionField, TextField } from 'react-admin';

import { Typography } from '@material-ui/core';

import { EnumField } from '../../common/inputs/EnumInputs';

export const RateAndNoteField = props => {
  return <FunctionField render={({ type }) => {
    if (type != 'Travel') return;
    return <div style={{ display: 'inline-flex', gap: 4, flexWrap: 'wrap' }}>
      <TextField source="rate" />
      <Typography variant="body2" color="textSecondary">/km</Typography>
    </div>
  }}
  {...props} />
}

export const ExcludedDistanceAndNoteField = props => {
  return <FunctionField render={({ type, excludedDistance }) => {
    if (type != 'Travel' || !excludedDistance) return;
    return <div style={{ display: 'inline-flex', gap: 4, flexWrap: 'wrap' }}>
      <TextField source="excludedDistance" />
      <Typography variant="body2" color="textSecondary">km(s)</Typography>
    </div>
  }}
  {...props} />
}

export const ClaimsSettingsGrid = props =>
  <Datagrid size="medium" {...props}>
    <TextField source="name" />
    <EnumField source="type" />
    <TextField source="amount" />
    <TextField source="rate" />
    <TextField source="excludedDistance" />
  </Datagrid>

