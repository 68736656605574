import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, makeStyles, ListItemIcon, ListItemText } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { Accessibility, Build as BuildIcon, Flag as FlagIcon } from '@material-ui/icons'

import { usePermissions } from '../http';
import { RolesAndPermissionsDialog } from './internalTools/RolesAndPermissionsDialog';
import { FeatureFlagDialog } from './internalTools/FeatureFlagDialog';

const useStyleToolButton = makeStyles(theme => ({
  button: {
    color: [theme.palette.tertiary.light, '!important'],
    opacity: 0.8,
    borderRadius: '50%',
    width: '2rem',
    height: '2rem',
  }
}));

const useStyleDropdownlButton = makeStyles(theme => ({
  button: {
    color: [theme.palette.tertiary.main, '!important'],
  }
}));

const InternalToolButton = ({ className, onClick }) => {
  const classes = useStyleToolButton();

  return <IconButton
    onClick={onClick}
    aria-haspopup
    className={` ${classes.button} ${className}`}
  >
    <BuildIcon fontSize="small" />
  </IconButton>
}

export const useCanShowInternalMenu = () => {
  const permissions = usePermissions();
  const canShowInternalMenu = permissions.some(p => p.roleType === 'System');
  return canShowInternalMenu;
};

export const InternalToolMenu = ({ className }) => {
  const translate = useTranslate();
  const classes = useStyleDropdownlButton();
  const [FFOpen, setFFOpen] = useState(false);
  const [rolesOpen, setRolesOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleFFOpen = () => {
    setFFOpen(true);
    handleClose();
  }

  const handleRolesOpen = () => {
    setRolesOpen(true);
    handleClose()
  }

  return <>
    <InternalToolButton onClick={handleMenu} className={className} />
    {FFOpen && <FeatureFlagDialog open={FFOpen} onClose={() => setFFOpen(false)} />}
    {rolesOpen && <RolesAndPermissionsDialog open={rolesOpen} onClose={() => setRolesOpen(false)} />}
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
      dense
    >
      <MenuItem onClick={handleFFOpen}>
        <ListItemIcon>
          <FlagIcon fontSize="small" className={classes.button} />
        </ListItemIcon>
        <ListItemText primary={translate('components.feature_flags.title')} />
      </MenuItem>
      <MenuItem onClick={handleRolesOpen}>
        <ListItemIcon>
          <Accessibility fontSize="small" className={classes.button} />
        </ListItemIcon>
        <ListItemText primary={translate('components.roles_and_permissions.title')} />
      </MenuItem>
    </Menu>
  </>
}
