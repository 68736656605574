import React from 'react';
import { SimpleForm, TextInput } from 'react-admin';
import { Grid } from '@material-ui/core';

import Toolbar from '../../common/ra/Toolbar';

const inputProps = {
  resource: 'pools',
  basePath: '/pools',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'ra.validation.required';
  }

  return errors;
};

export const PoolForm = ({ initialValues, ...props }) =>
  <SimpleForm toolbar={<Toolbar />} validate={validate} initialValues={initialValues} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <TextInput source="name" helperText="" {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
