import React from 'react';
import { SimpleForm, NumberInput, TextInput } from 'react-admin';
import { Grid } from '@material-ui/core'

import Toolbar from '../../common/ra/Toolbar';
import { OfficeClaimsTypesEnumInput } from '../../common/inputs/EnumInputs';
import { FieldDependency } from '../../common/FieldDependency';
import { ArrayI18nInput } from '../../common/inputs/I18nInput';

const validate = values => {
  const errors = {};
  if (!values.name) errors.name = 'ra.validation.required';
  if (!values.type) errors.type = 'ra.validation.required';
  if (values.type == 'Travel' && !values.rate) errors.rate = 'ra.validation.required';

  return errors;
}

const inputProps = {
  resource: 'officeclaimssettings',
  basePath: '/officeClaimsSettings',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const OfficeClaimsSettingsForm = props => {
  const isAmount = ['Per Diem', 'Expense']

  return <SimpleForm toolbar={<Toolbar /> } validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12} md={8}>
        <TextInput source="name" label="resources.officeClaimsSettings.fields.name" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={4}>
        <OfficeClaimsTypesEnumInput source="type" label="resources.officeClaimsSettings.fields.type" {...inputProps} />
      </Grid>
      <Grid item xs={12} >
        <ArrayI18nInput {...inputProps} />
      </Grid>
      <FieldDependency fieldSource="type" test={type => type == 'Travel'}>
        <Grid item xs={12} lg={6}>
          <NumberInput
            source="rate"
            label="resources.officeClaimsSettings.fields.rate"
            helperText="resources.officeClaimsSettings.helpers.rate"
            {...inputProps}
          />
        </Grid>
      </FieldDependency>
      <FieldDependency fieldSource="type" test={type => type == 'Travel'}>
        <Grid item xs={12} lg={6}>
          <NumberInput
            source="excludedDistance"
            label="resources.officeClaimsSettings.fields.excludedDistance"
            helperText="resources.officeClaimsSettings.helpers.excludedDistance"
            {...inputProps}
          />
        </Grid>
      </FieldDependency>
      <FieldDependency fieldSource="type" test={type => isAmount.includes(type)}>
        <Grid item xs={12} lg={6}>
          <NumberInput
            source="amount"
            label="resources.officeClaimsSettings.fields.amount"
            helperText="resources.officeClaimsSettings.helpers.amount"
            {...inputProps}
          />
        </Grid>
      </FieldDependency>
    </Grid>
  </SimpleForm>
}
