import React from 'react';
import { useLocale, useRecordContext } from 'react-admin';
import { EmojiPeople } from '@material-ui/icons';

import { translateApiProperty } from '@hisports/common';

import ListCard from '../../../common/cards/ListCard';
import { AddClaimsSettingsButton, OfficeClaimsSettingsDataGrid } from '../../officialClaims/ClaimSettingsModal';
import { TypeAndNoteField } from '../../officialTransactions/TransactionsGrid';
import { ExcludedDistanceAndNoteField, RateAndNoteField } from '../../officialClaims/ClaimsSettingsGrid';
import { CurrencyField } from '../../../common/fields/CurrencyField';
import FunctionField from '../../../common/fields/FunctionField';

export default ({ ...props }) => {
  const office = useRecordContext(props);
  const locale = useLocale();

  return <ListCard
    title="resources.officeClaimsSettings.labels.name"
    icon={<EmojiPeople />}
    reference="officeClaimsSettings"
    target="officeId"
    sort={{ field: 'name', order: 'ASC' }}
    addButton={<AddClaimsSettingsButton
      target="officeId"
      initialValues={{
        officeId: office?.id,
      }}
    />}
  >
    <OfficeClaimsSettingsDataGrid rowClick="show" >
      <FunctionField source="name" render={record => translateApiProperty(record, 'name', locale)} />
      <TypeAndNoteField source="type" sortable={false} />
      <CurrencyField source="amount" />
      <RateAndNoteField source="rate" />
      <ExcludedDistanceAndNoteField source="excludedDistance" />
    </OfficeClaimsSettingsDataGrid>
  </ListCard>
}
