import React, { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, Typography, Grid, IconButton, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Alert } from '@material-ui/lab'
import { camelCase, startCase } from 'lodash';

import { FF_CATEGORIES } from '@hisports/common/feature-flags-categories';

import { useFlags, useTenant } from '../../http';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  alert: {
    marginBottom: theme.spacing(2),
  }
}))

export const FeatureFlagDialog = ({ open, onClose }) => {
  const classes = useStyles();
  const flags = useFlags();
  const tenant = useTenant();
  const initialValues = useMemo(() => flags.reduce((initialValues, flag) => {
    initialValues[flag] = true;
    return initialValues;
  }, {}), [flags]);

  const translate = useTranslate();

  return <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
    <DialogTitle> {`${translate('components.feature_flags.title')} - ${tenant?.name}`}</DialogTitle>
    <div className={classes.closeButton}>
      <IconButton onClick={onClose}>
        <Close fontSize="small" />
      </IconButton>
    </div>
    <DialogContent>
      <Alert severity="info" className={classes.alert}>
        {translate('components.feature_flags.alerts.branch_level')}
      </Alert>
      {Object.entries(FF_CATEGORIES).map(([category, flags]) => <div key={category}>
        <Typography variant="subtitle2" title={category}>{startCase(camelCase(category))}</Typography>
        <br />
        <Grid container spacing={3} style={{ marginBottom: 16 }}>
          {flags.map(flag => <Grid key={flag.id} item xs={12} md={4}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{ width: '1.1rem', height: '1.1rem', background: initialValues[flag.id || flag] ? '#4caf50' : '#ff1744', borderRadius: '50%', marginTop: 'auto' }}
                title={initialValues[flag.id || flag] ? 'enabled' : 'disabled'}
              />
              <div style={{ marginLeft: '1rem', fontWeight: 'bold', flex: 1, fontSize: '.9rem' }} title={flag.id || flag}>
                {(flag.id || flag).toUpperCase()}
              </div>
            </div>
            {flag.description && <div style={{ color: 'grey', fontSize: '.8rem', marginTop: '.5rem' }}>
              {flag.description}
            </div>}
          </Grid>
          )}
        </Grid>
      </div>)}
    </DialogContent>
  </Dialog>
};
