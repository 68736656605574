import React from 'react';
import { BooleanInput, NullableBooleanInput, TextInput, useRecordContext } from 'react-admin'

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';
import { useScopes } from '../../../http';
import { GameIcon } from '../../icons';
import { InlineDateInput } from '../../../common/inputs/DateInput';

import { EventView } from '../../events/EventView';
import { AddGameButton } from '../../games/GameModal';
import { GroupInput } from '../../groups/GroupInput';
import { TeamInput } from '../../teams/TeamInput';
import { SurfaceInput } from '../../surfaces/SurfaceInput';
import { CALENDAR_VIEWS, EventViewSettingsMenu, useEventSort, useShowCalendar } from '../../events/EventViewSettings';

const multipleProps = {
  multiple: true,
  allowEmpty: false,
}

const getFilters = (schedule, scopes, showCalendar) => {
  const canListTeams = isAuthorized(scopes, 'teams', 'list');
  const canListSurfaces = isAuthorized(scopes, 'surfaces', 'list');

  const scheduleType = schedule?.type;
  const officeId = schedule?.officeId;

  const categoryIds = schedule && [schedule.categoryId];

  return [
    <TextInput source="number" variant="outlined" alwaysOn />,
    <InlineDateInput source="startTime" label="resources.games.filters.startTime" variant="outlined" alwaysOn />,
    <InlineDateInput source="endTime" label="resources.games.filters.endTime" variant="outlined" disabled={showCalendar} alwaysOn />,

    canListTeams && <TeamInput source="teamId" label="resources.teams.fields.teamId" filter={{ scheduleId: schedule?.id, seasonId: schedule?.seasonId }} variant="outlined" {...multipleProps} />,
    canListSurfaces && <SurfaceInput source="arenaId" variant="outlined" {...multipleProps} />,
    <NullableBooleanInput source="isApproved" label="resources.games.filters.isApproved" variant="outlined" />,
    <BooleanInput source="conflictsOnly" label="resources.games.filters.conflictsOnly" variant="outlined" />,
    <BooleanInput source="bracketGamesOnly" label="resources.games.filters.bracketGamesOnly" variant="outlined" />,
    <GroupInput
      source="groupId"
      variant="outlined"
      scheduleType={scheduleType}
      officeId={officeId}
      categoryIds={categoryIds}
      allowEmpty
      alwaysOn
    />,
  ].filter(Boolean)
}

export default props => {
  const schedule = useRecordContext(props);
  const scopes = useScopes();
  const canAddGames = isAuthorized(schedule, 'games', 'create')
  const [ showCalendar ] = useShowCalendar();
  const sort = useEventSort();

  const addButton = canAddGames && <AddGameButton size="small" initialValues={{ scheduleId: schedule?.id }} />
  const actions = [
    <EventViewSettingsMenu alwaysOn />,
  ].filter(Boolean)
  const filters = getFilters(schedule, scopes, showCalendar);

  return <ListCard
    title="resources.games.labels.card.title"
    icon={<GameIcon />}
    reference="games"
    target="scheduleId"
    addButton={addButton}
    actions={actions}
    filters={filters}
    filter={{ _include: ["gameBracket"] }}
    sort={sort}
    rowsPerPageOptions={showCalendar ? [] : [25, 50, 100]}
    perPage={showCalendar ? 99999 : 25}
    noResultsText={!showCalendar}
  >
    <EventView rowClick="show" strictView={CALENDAR_VIEWS.WEEK} disableScheduling />
  </ListCard>
}
