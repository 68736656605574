import React, { useCallback, useEffect, useState } from 'react';
import { Datagrid, List, Show, TextField, useRecordContext } from "react-admin";
import { Grid, makeStyles } from '@material-ui/core';
import { apiClient } from '../../http';

export const RoleList = ({ ...props }) => {
  return <List
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
    </Datagrid>
  </List>
};

const useScopesByRole = (props, setErrorMessage) => {
  const role = useRecordContext(props);

  const [scopes, setScopes] = useState([]);

  const reloadScopes = useCallback(async () => {
    try {
      setErrorMessage();
      const { data } = await apiClient(`/roles/${role.id}/scopes`);
      setScopes(data);
    } catch (error) {
      if (error.isAxiosError) {
        setErrorMessage(error.response.data.error.message);
      } else {
        setErrorMessage(error.message);
      }
    }
  }, [role.id, setErrorMessage]);

  useEffect(() => {
    reloadScopes();
  }, [reloadScopes]);

  return { scopes, reloadScopes };
};

const useTables = () => {
  const [tables, setTables] = useState([]);
  useEffect(() => {
    apiClient('/roles/tables').then(({ data }) => {
      setTables(data);
    });
  }, []);
  return tables;
};

// #region sub component
const useStylesSubScope = makeStyles(theme => ({
  main: {
    borderBottom: '1px solid gray',
    paddingBottom: '1rem',
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  deleteScope: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    borderRadius: '.5rem',
    backgroundColor: '#ff1744',
    borderColor: '#870d25',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: '.1rem',
    cursor: 'pointer',
    marginLeft: 'auto',
  },
  deleteColumn: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    borderRadius: '.5rem',
    backgroundColor: '#ff1744',
    borderColor: '#870d25',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: '.1rem',
    cursor: 'pointer',
  },
  addColumn: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    borderRadius: '.5rem',
    backgroundColor: 'green',
    borderColor: '#870d25',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    cursor: 'pointer',
    letterSpacing: '.1rem',
  },
}));
const SubScopeComponent = ({ scope, reloadScopes, setErrorMessage }) => {
  const role = useRecordContext();

  const [value, setValue] = useState('');
  const onClick = useCallback(async () => {
    try {
      setErrorMessage();
      await apiClient(`/roles/${role.id}/scopes/${scope.id}/columns`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          column: value,
        }
      });
      setValue('');
      await reloadScopes();
    } catch (error) {
      if (error.isAxiosError) {
        setErrorMessage(error.response.data.error.message);
      } else {
        setErrorMessage(error.message);
      }
    }
  }, [value, reloadScopes, role.id, scope.id, setErrorMessage]);

  const deleteScope = useCallback(async () => {
    try {
      setErrorMessage();
      await apiClient(`/roles/${role.id}/scopes/${scope.id}`, {
        method: 'DELETE',
      });
      await reloadScopes();
    } catch (error) {
      if (error.isAxiosError) {
        setErrorMessage(error.response.data.error.message);
      } else {
        setErrorMessage(error.message);
      }
    }
  }, [reloadScopes, role.id, scope.id, setErrorMessage]);

  const deleteScopeColumn = useCallback(async (column) => {
    try {
      setErrorMessage();
      await apiClient(`/roles/${role.id}/scopes/${scope.id}/columns`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          column,
        }
      });
      await reloadScopes();
    } catch (error) {
      if (error.isAxiosError) {
        setErrorMessage(error.response.data.error.message);
      } else {
        setErrorMessage(error.message);
      }
    }
  }, [reloadScopes, role.id, scope.id, setErrorMessage]);

  const style = useStylesSubScope();

  return <li>
    <div className={style.main}><strong style={{ color: 'blue' }}>{scope.type} : </strong> <span style={{ color: 'yellowgreen', marginLeft: '.3rem' }}> {scope.context}</span> <button onClick={deleteScope} className={style.deleteScope}>delete</button></div>
    <ul>
      {scope.columns.map((c, i) => <li style={{ display: 'flex', alignItems: 'center', marginBottom: '.5rem' }} key={`sc${c}`} >
        <span style={{ fontStyle: 'italic' }}>{c}</span>
        <div style={{ width: '100%', background: 'gray', height: '1px', marginLeft: '1rem', marginRight: '1rem' }}></div>
        <button onClick={deleteScopeColumn.bind(undefined, c)} className={style.deleteColumn}
        >delete</button>
      </li>)}
      <li style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <SelectComponent values={scope.availables
          .filter(x => !scope.columns.includes(x))
          .map(e => ({ id: e, name: e }))
        } setValue={setValue} />
        <button onClick={onClick} className={style.addColumn}>save</button>
      </li>
    </ul>
  </li>
};
// #endregion

// #region select component
const useStylesSelect = makeStyles(() => ({
  select: {
    padding: '.5rem',
    background: 'purple',
    color: 'white',
    borderRadius: '.5rem'
  },
}))
const SelectComponent = ({ values = [], setValue }) => {
  const styles = useStylesSelect();
  const onChange = useCallback(event => {
    const { value } = event.target;
    setValue(value ? value : undefined);
  }, [setValue]);
  return <select onChange={onChange} required className={styles.select}>
    <option>Choose your option</option>
    {values.map((v, i) => <option value={v.id} key={v.name}>{v.name}</option>)}
  </select>
};
// #endregion

// #region scopes component
const useStylesScopes = makeStyles(() => ({
  title: {
    borderBottom: '1px solid gray',
    paddingBottom: '1rem',
  },
  createScopeTitle: {
    borderBottom: '1px solid gray',
    paddingBottom: '1rem',
    marginTop: '1rem'
  },
  saveButton: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    cursor: 'pointer',
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    borderRadius: '.5rem',
    backgroundColor: 'green',
    borderColor: '#870d25',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: '.1rem',
    marginTop: '1rem'
  },
}));
const ScopesComponent = (props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const { scopes, reloadScopes } = useScopesByRole(props, setErrorMessage);
  const role = useRecordContext(props);
  const [typeScope, setTypeScope] = useState();
  const [value, setValue] = useState('');
  const tables = useTables();
  const styles = useStylesScopes();

  const onChange = useCallback(event => {
    setValue(event.target.value);
  }, []);
  const onClick = useCallback(async () => {
    try {
      setErrorMessage();
      await apiClient(`/roles/${role.id}/scopes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          type: typeScope,
          context: value
        }
      });
      setValue('');
      await reloadScopes();
    } catch (error) {
      if (error.isAxiosError) {
        setErrorMessage(error.response.data.error.message);
      } else {
        setErrorMessage(error.message);
      }
    }
  }, [value, reloadScopes, role.id, typeScope]);

  const setSecondPart = useCallback((a) => {
    setValue(old => {
      if (old?.includes(':')) {
        return `${old.split(':').shift()}:${a}`;
      }
      return `:${a}`;
    })
  }, []);

  const setFirstPart = useCallback((a) => {
    setValue(old => {
      if (old?.includes(':')) {
        return `${a}:${old.split(':').pop()}`;
      }
      return `${a}:`;
    })
  }, []);

  return <>
    <div>
      <h3 className={styles.title}>Scopes</h3>
      <ul>
        {scopes.map(s => <SubScopeComponent scope={s} reloadScopes={reloadScopes} key={s.id} setErrorMessage={setErrorMessage} />)}
      </ul>
    </div>
    <div>
      <h3 className={styles.createScopeTitle}>Create scope</h3>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <SelectComponent
          values={[{ id: 'resource', name: 'resource' }, { id: 'action', name: 'action' }]}
          setValue={setTypeScope}
        />
        {typeScope === 'resource' ? <>
          <SelectComponent values={tables.map(e => ({ id: e, name: e }))} setValue={setFirstPart} />
          <SelectComponent values={['view', 'create', 'delete', 'update'].map(e => ({ id: e, name: e }))} setValue={setSecondPart} />
        </> : <input value={value} onChange={onChange} placeholder="scope" style={{ padding: '.5rem', borderRadius: '.5rem' }} />}
      </div>
      <button onClick={onClick} className={`special-button ${styles.saveButton}`}>save</button>
    </div>
    {errorMessage && <strong style={{ color: 'red' }}>{errorMessage}</strong>}
  </>;
};
// #endregion

export const RoleShow = ({ ...props }) => {
  return <Show
    {...props}
  >

    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div style={{ padding: '1rem' }}>
          <h2>Role : <TextField source="name" /></h2>
          <ScopesComponent />
        </div>
      </Grid>
    </Grid>

  </Show>
};
