import React, { Fragment, useEffect, useMemo } from 'react';
import { useNotify, SimpleForm, ArrayInput, RecordContextProvider, useRefresh, useTranslate } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { Dialog, DialogTitle, DialogContent, useMediaQuery, Grid, makeStyles } from '@material-ui/core';
import { isEmpty, get } from 'lodash';

import { translateApiProperty } from '@hisports/common';
import { EVENT_TYPE_PENALTY, HOME, AWAY, SHOOTOUT_PERIOD } from '@hisports/scoresheet/src/constants';
import { startPenalty, addGoal } from '@hisports/scoresheet/src/actions';
import { findAdditionalPenalties, getInfractionOptionCodes, getInfractionOptionLabel, getInfractionRuleLabel, getLinkedPenalties, isLegacyRulebookSeason, stringifyGameTime } from '@hisports/scoresheet/src/util';
import { getPenalties } from '@hisports/scoresheet/src/selectors';
import { FF_HIDE_INFRACTION_NAME, FF_PENALTY_FILL_START, FF_PENALTY_SERVED_BY, FF_PENALTY_START_END } from '@hisports/common/featureFlags';

import { useMeta, useScoresheetDispatch, useScoresheetStore } from "../ScoresheetContext"

import { useLocale } from '../../../locale';
import { useFlag, useSport } from '../../../http';
import { GameTimeInput } from '../components/GameTimeInput';
import HorizontalFormIterator, { TransitionProps } from '../../../common/ra/HorizontalFormIterator';
import { EnumInput } from '../../../common/inputs/EnumInputs';
import { DialogFormToolbar } from '../../../common/dialogs/DialogForm';
import { LineupInput } from '../lineup/LineupInput';
import { TeamField } from '../../teams/TeamField';
import { parseGoalEvent, parsePenaltyEvent, validateEvent } from './SummaryEditModal';

const inputProps = {
  variant: 'outlined',
  size: 'small',
  margin: 'dense',
  fullWidth: true,
}

const useStyles = makeStyles({
  iterator: {
    // fix for HorizontalFormIterator disableReordering generating a ui misalignment
    marginLeft: '-8px !important',
  }
})

const isNotEmpty = obj => !isEmpty(obj)

const validate = (values, scoresheet, sport) => {
  const errors = {
    events: {
      penalty: {},
      goal: {},
    }
  };

  ['penalty', 'goal'].forEach(eventType => {
    ['home', 'away'].forEach(teamType => {
      errors.events[eventType][teamType] = (values?.events?.[eventType]?.[teamType] || []).map(event => {
        const { event: eventErrors } = validateEvent({ ...event, eventType }, scoresheet, sport)
        return eventErrors;
      })
    })
  })

  return errors;
}

const InfractionChoiceInput = ({ helperText, ...props }) => {
  const isEnabled = useFlag();
  const { meta, game } = useMeta();
  const { infractions = [], rules = [], types, options = [] } = meta;
  const { values } = useFormState();
  const [ locale ] = useLocale();
  const choiceId = get(values, props.source);

  const choices = useMemo(() => {
    const choices = []

    if (isLegacyRulebookSeason(game.seasonId)) {
      rules.forEach(rule => {
        rule.choices.forEach(choice => {
          choices.push({
            id: choice.id,
            name: getInfractionOptionLabel(choice, types, game.seasonId),
            rule: rule.name,
          })
        })
      })
    } else {
      options.forEach(option => {
        const rule = rules.find(rule => rule.id === option.ruleId)
        choices.push({
          id: option.id,
          name: getInfractionOptionLabel(option, types, game.seasonId, locale),
          description: getInfractionOptionCodes(option, infractions).filter(Boolean).join(' + '),
          rule: getInfractionRuleLabel(rule, locale)
        })
      })
    }
    return choices
  }, [infractions, rules, types, options, game.seasonId, locale])

  return <EnumInput
    choices={choices}
    optionDescription="description"
    filterKeys={['rule', 'name', 'description']}
    groupBy={({ rule }) => rule }
    helperText={(() => {
      if (!choiceId) return helperText;
      if (isEnabled(FF_HIDE_INFRACTION_NAME)) return helperText; // this would just show "Card"
      const choice = choices.find(choice => choice.id === choiceId)
      return choice?.rule || helperText;
    })()}
    {...props}
  />
}

const AccumulationChoiceInput = ({ helperText, ...props }) => {
  const { meta, game } = useMeta();
  const { accumulations = [] } = meta;
  const [ locale ] = useLocale();

  const choices = useMemo(() => {
    const choices = [];

    if (isLegacyRulebookSeason(game.seasonId)) {
      return choices;
    } else {
      accumulations.forEach(option => {
        choices.push({
          id: option.id,
          name: translateApiProperty(option, 'name', locale)
        })
      })
    }
    return choices;
  }, [accumulations, game.seasonId, locale])

  return <EnumInput
    choices={choices}
    {...props}
  />
}

const GoalTypeInput = props => {
  const choices = [
    { id: 'empty_net', name: 'resources.gameEvents.labels.goal.empty_net', sports: ['Hockey'] },
    { id: 'powerplay', name: 'resources.gameEvents.labels.goal.powerplay', sports: ['Hockey'] },
    { id: 'shorthanded', name: 'resources.gameEvents.labels.goal.shorthanded', sports: ['Hockey'] },
    { id: 'penalty_shot', name: 'resources.gameEvents.labels.goal.penalty_shot', sports: ['Hockey', 'Soccer'] },
    { id: 'own_goal', name: 'resources.gameEvents.labels.goal.own_goal', sports: ['Soccer'] },
  ]

  return <EnumInput {...props} multiple select choices={choices} />
}

const GoalEventInput = ({ source, teamId }) => {
  const form = useForm();
  const { values } = useFormState();
  const event = get(values, source);
  const period = event?.gameTime?.period;
  const scorerId = event?.participantId;
  const assistIds = event?.assistIds;
  const goalTypes = event?.goalTypes;

  useEffect(() => {
    if (goalTypes?.includes('own_goal')) {
      form.batch(() => {
        form.change(`${source}.participantId`, null)
        form.change(`${source}.assistIds`, null)
      })
    }
  }, [goalTypes]) // eslint-disable-line react-hooks/exhaustive-deps

  return <Grid container spacing={1} fullWidth>
    <Grid item xs={12} md={2}>
      <GameTimeInput source={`${source}.gameTime`} label="resources.scoresheets.labels.goal.game_time" teamId={teamId} {...inputProps} />
    </Grid>
    <Grid item xs={12} md={2}>
      <GoalTypeInput source={`${source}.goalTypes`} select label="resources.scoresheets.labels.goal.type" helperText="ra.message.optional" disabled={period === SHOOTOUT_PERIOD} teamId={teamId} {...inputProps} />
    </Grid>
    <Grid item xs={12} md={4}>
      <LineupInput source={`${source}.participantId`} exclude={assistIds} excludeStaff disabled={goalTypes?.includes('own_goal')} select label="resources.scoresheets.labels.goal.scored_by" teamId={teamId} {...inputProps} />
    </Grid>
    <Grid item xs={12} md={4}>
      <LineupInput source={`${source}.assistIds`} exclude={scorerId} excludeStaff disabled={period === SHOOTOUT_PERIOD || goalTypes?.includes('own_goal')} select multiple helperText="ra.message.optional" label="resources.scoresheets.labels.goal.assisted_by" teamId={teamId} {...inputProps} />
    </Grid>
  </Grid>
}

const PenaltyEventInput = ({ source, teamId }) => {
  const { values, dirtyFields } = useFormState();
  const isEnabled = useFlag();
  const form = useForm();
  const event = get(values, source);
  const store = useScoresheetStore();
  const { meta, game } = useMeta();
  const translate = useTranslate();
  const sport = useSport();

  const isAccumulationPenalty = !!event?.accumulationId
  const isSubsequentPenalty = useMemo(() => {
    if (!event?.id) return false
    const scoresheet = store.getState();
    const teamPenalties = getPenalties(scoresheet, meta.infractions, meta.types, sport, game.seasonId, { teamId: event.teamId })
    const linkedPenalties = isLegacyRulebookSeason(game.seasonId)
      ? findAdditionalPenalties(event, teamPenalties, meta.rules, sport, true)
      : getLinkedPenalties(event, teamPenalties, sport)

    const subsequenPenalties = linkedPenalties.slice(1);
    return subsequenPenalties.some(linkedPenalty => linkedPenalty.id === event.id);
  }, [ store, meta.infractions, meta.types, meta.rules, sport, game.seasonId, event ])

  useEffect(() => {
    if (!isEnabled(FF_PENALTY_FILL_START)) return
    if (isEmpty(event?.gameTime) || (!dirtyFields[`${source}.gameTime.clock`] && !dirtyFields[`${source}.gameTime.period`])) return
    form.change(`${source}.startTime`, event.gameTime)
  }, [ event?.gameTime, dirtyFields, form, source, isEnabled ])

  return <Grid container spacing={1} fullWidth>
    <Grid item xs={12} md={2}>
      <GameTimeInput source={`${source}.gameTime`} label="resources.scoresheets.labels.penalty.game_time" teamId={teamId} disabled={isSubsequentPenalty} {...inputProps} />
    </Grid>
    {isEnabled(FF_PENALTY_START_END) && <Grid item xs={12} md={2}>
      <GameTimeInput source={`${source}.startTime`} label="resources.scoresheets.labels.penalty.start_time" placeholder={stringifyGameTime(event?.gameTime?.clock)} teamId={teamId} {...inputProps} />
    </Grid>}
    <Grid item xs={12} md>
      <LineupInput source={`${source}.participantId`} select label="resources.scoresheets.labels.penalty.offender" teamId={teamId} disabled={isSubsequentPenalty} {...inputProps} />
    </Grid>
    {isEnabled(FF_PENALTY_SERVED_BY) && <Grid item xs={12} md>
      <LineupInput source={`${source}.servedById`} select exclude={event?.participantId} label="resources.scoresheets.labels.penalty.served_by" teamId={teamId} disabled={isSubsequentPenalty} {...inputProps} />
    </Grid>}
    {!isAccumulationPenalty && <Grid item xs={12} md>
      <InfractionChoiceInput source={`${source}.optionId`} label="resources.scoresheets.labels.penalty.infraction" disabled={isSubsequentPenalty} {...inputProps} />
    </Grid>}
    {isAccumulationPenalty && <Grid item xs={12} md>
      <AccumulationChoiceInput source={`${source}.accumulationId`} label={translate("resources.infractionAccumulations.name", 1)} disabled {...inputProps} />
    </Grid>}
  </Grid>
}

export const GameEventInput = ({ eventType, ...props }) => {
  return eventType === EVENT_TYPE_PENALTY
    ? <PenaltyEventInput {...props} />
    : <GoalEventInput {...props} />;
}

const GameEventsInput = ({ source, teamId, eventType, ...props }) => {
  const classes = useStyles();

  return <ArrayInput source={source} label="" {...inputProps}>
    <HorizontalFormIterator disableReordering TransitionProps={TransitionProps} {...inputProps}>
      <GameEventInput teamId={teamId} className={classes.iterator} eventType={eventType} />
    </HorizontalFormIterator>
  </ArrayInput>
}

const SummaryForm = ({ events = [], ...props }) => {
  const { game } = useMeta();
  const { homeTeamId, awayTeamId } = game;

  return <SimpleForm {...props} {...inputProps}>
    <>
      <TeamField record={{ teamId: homeTeamId }} source="teamId" link={false} variant="subtitle2" />
      <GameEventsInput source={`events.${props.eventType}.${HOME}`} teamId={homeTeamId} {...props} />
      <TeamField record={{ teamId: awayTeamId }} source="teamId" link={false} variant="subtitle2" />
      <GameEventsInput source={`events.${props.eventType}.${AWAY}`} teamId={awayTeamId} {...props} />
    </>
  </SimpleForm>
}

export const SummaryBulkAddModal = ({ title, eventType, open, setModalOpen, ...props }) => {
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const translate = useTranslate();
  const dispatch = useScoresheetDispatch();
  const store = useScoresheetStore();
  const notify = useNotify();
  const refresh = useRefresh();
  const { meta, game } = useMeta();
  const sport = useSport();

  const onSubmit = async (values = {}) => {
    const { events = {}, id } = values;
    const { homeTeamId, awayTeamId } = game;
    let numberOfFulfilled = 0;

    const infractions = [
      ...(events?.penalty?.home || []).filter(isNotEmpty).map(event => ({ ...event, teamId: homeTeamId })),
      ...(events?.penalty?.away || []).filter(isNotEmpty).map(event => ({ ...event, teamId: awayTeamId })),
    ]
    const goals = [
      ...(events?.goal?.home || []).filter(isNotEmpty).map(event => ({ ...event, teamId: event.goalTypes?.includes('own_goal') ? awayTeamId : homeTeamId })),
      ...(events?.goal?.away || []).filter(isNotEmpty).map(event => ({ ...event, teamId: event.goalTypes?.includes('own_goal') ? homeTeamId : awayTeamId })),
    ]

    // dispatch penalties synchronously to make sure PenaltyManager has the current state of the team penalties after each infraction
    for (const infraction of infractions) {
      const scoresheetState = store.getState();
      const penalties = parsePenaltyEvent(infraction, scoresheetState, meta, sport, game.seasonId)
      const dispatches = penalties.map(event => startPenalty(game.id, event));
      const results = await Promise.allSettled(dispatches.map(event => {
        try {
          return dispatch(event)
        } catch (e) {
          // catch validation errors caused by the dispatches
          return Promise.reject(e)
        }
      }));
      numberOfFulfilled += (results || []).filter(result => result.status === 'fulfilled').length;
    }

    // dispatch all goals asynchronously
    if (goals.length) {
      const dispatches = goals.map(parseGoalEvent).map(event => addGoal(game.id, event));

      const results = await Promise.allSettled(dispatches.map(event => {
        try {
          return dispatch(event)
        } catch (e) {
          // catch validation errors caused by the dispatches
          return Promise.reject(e)
        }
      }))
      numberOfFulfilled += (results || []).filter(result => result.status === 'fulfilled').length
    }

    const isSuccessful = numberOfFulfilled
    const message = isSuccessful ? `resources.scoresheets.messages.${eventType}.added` : `resources.scoresheets.messages.${eventType}.not_added`;
    const notificationType = isSuccessful ? 'info' : 'error';

    notify(message, notificationType, numberOfFulfilled)
    setModalOpen(false);
    setTimeout(() => refresh(), 500);
  }
  const onClose = () => {
    setModalOpen(false);
  }

  return <Dialog open={open} maxWidth="lg" fullWidth fullScreen={fullScreen}>
    <DialogTitle>{translate(`resources.scoresheets.labels.add_${eventType === 'penalty' ? 'penalties' : 'goals'}`)}</DialogTitle>
    <DialogContent>
      <RecordContextProvider value={null}>
        <SummaryForm
          eventType={eventType}
          validate={values => validate(values, store.getState(), sport)}
          initialValues={{ events: { [eventType]: { home: [undefined], away: [undefined] } } }}
          save={onSubmit} component={Fragment} toolbar={
            <DialogFormToolbar submitLabel="ra.action.save" cancelLabel="ra.action.cancel" onCancel={onClose} />
          } />
      </RecordContextProvider>
    </DialogContent>
  </Dialog>
}
