import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import { OfficeClaimsSettingsForm } from './ClaimSettingsForm';

const AddOfficeClaimsSettingsDialog = props =>
  <CreateResourceDialog label="resources.officeClaimsSettings.actions.add" maxWidth="md" resource="officeClaimsSettings" basePath="/officeclaimssettings" {...props}>
    <OfficeClaimsSettingsForm />
  </CreateResourceDialog>

export const AddClaimsSettingsButton = ({ size, variant, className, disabled, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();

  return <>
    <Button color="primary" size={size} variant={variant} className={className} startIcon={<Add />} disabled={disabled} onClick={() => setOpen(true)}>
      {translate('resources.officeClaimsSettings.actions.add')}
    </Button>
    <AddOfficeClaimsSettingsDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const EditOfficeClaimsSettingsDialog = props =>
  <EditResourceDialog label="resources.officialClaims.labels.edit" maxWidth="md" resource="officeClaimsSettings" basePath="/officeclaimssettings" {...props}>
    <OfficeClaimsSettingsForm />
  </EditResourceDialog>

export const OfficeClaimsSettingsDataGrid = props => <DialogDatagrid dialog={EditOfficeClaimsSettingsDialog} {...props} />
