import React, { Fragment, useState } from 'react';
import { TextInput, SimpleForm, useRecordContext, useTranslate, useNotify } from 'react-admin';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { containsLowercase, containsNumber, containsSpecialCharacter, containsUppercase, meetsLength } from '@hisports/common/src/passwords';

import PasswordInput from '../../common/inputs/PasswordInput';
import { DialogFormToolbar } from '../../common/dialogs/DialogForm';
import { apiClient } from '../../http';

const MIN_PASS_LENGTH = 8;

const inputProps = {
  resource: 'accounts',
  basePath: '/accounts',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const validate = (values, translate) => {
  const errors = {};

  if (!values.email) errors.email = 'ra.validation.required';

  if (values.externalId) {
    const password = values.password;
    if (!values.password) {
      errors.password = 'ra.validation.required';
    } else if (!meetsLength(password, MIN_PASS_LENGTH)) {
      errors.password = translate('resources.accounts.validations.must_be_length', { length: MIN_PASS_LENGTH })
    } else if (!containsNumber(password)) {
      errors.password = 'resources.accounts.validations.must_have_number'
    } else if (!containsSpecialCharacter(password)) {
      errors.password = 'resources.accounts.validations.must_have_special'
    } else if (!containsUppercase(password)) {
      errors.password = 'resources.accounts.validations.must_have_uppercase'
    } else if (!containsLowercase(password)) {
      errors.password = 'resources.accounts.validations.must_have_lowercase'
    }
  }

  return errors;
}

const SMAResetAlert = () => {
  const translate = useTranslate();

  return <Alert severity="info" fullWidth style={{ marginBottom: 24 }}>
    <AlertTitle>
      {translate('resources.accounts.alerts.sma_reset_title')}
    </AlertTitle>
    {translate('resources.accounts.alerts.sma_reset')}
  </Alert>
}

const SMAResetPasswordForm = ({ onSubmit, onClose }) => {
  const translate = useTranslate();

  const toolbar = <DialogFormToolbar handleSubmit={onSubmit} onCancel={onClose} submitLabel="ra.action.reset" />

  return <SimpleForm component={Fragment} toolbar={toolbar} validate={values => validate(values, translate)} autoComplete="off" save={onSubmit}>
    <TextInput source="email" {...inputProps} disabled />
    <PasswordInput source="password" {...inputProps} />
  </SimpleForm>
}


const SMAResetPasswordDialog = ({ open, setOpen }) => {
  const translate = useTranslate();
  const notify = useNotify();

  const onSubmit = ({ id: accountId, password }) => {
    return apiClient(`/accounts/${accountId}/ssoResetPassword`, {
      method: 'POST',
      params: { password }
    })
      .then(() => {
        notify('resources.accounts.notifications.sma_reset_success', 'info');
      })
      .catch(() => {
        notify('resources.accounts.notifications.sma_reset_error', 'error')
      })
      .finally(() => {
        setOpen(false);
      })
  }

  return <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
    <DialogTitle>{translate('resources.accounts.actions.sma_reset')}</DialogTitle>
    <DialogContent>
      <SMAResetAlert />
      <SMAResetPasswordForm onSubmit={onSubmit} onClose={() => setOpen(false)} />
    </DialogContent>
  </Dialog>
}

export const SMAResetPasswordButton = ({ ...props }) => {
  const translate = useTranslate();
  const account = useRecordContext();
  const [open, setOpen] = useState(false);

  if (!account?.externalId) return null;
  return <>
    <Button color="primary" label="resources.accounts.actions.sma_reset" onClick={() => setOpen(true)} {...props}>
      {translate('resources.accounts.actions.sma_reset')}
    </Button>
    {open && <SMAResetPasswordDialog setOpen={setOpen} open={open} />}
  </>
}
