import React, { useEffect, useState } from 'react';
import { useListContext, useRecordContext, useTranslate } from 'react-admin';
import { Button, IconButton } from '@material-ui/core'
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import moment from 'moment-timezone';

import { createRange } from '@hisports/common';

import { CALENDAR_VIEWS } from '../../resources/events/EventViewSettings';

import { useCalendarContext } from './CalendarContext';
import { useSchedulingContext } from './SchedulingContext';

const TODAY = moment.utc().format('YYYY-MM-DD')

export const getRangeStart = (type, date = TODAY) => {
  if (type === CALENDAR_VIEWS.WEEK) {
    return moment.utc(date, 'YYYY-MM-DD').startOf('week').format('YYYY-MM-DD');
  } else if (type === CALENDAR_VIEWS.MONTH) {
    return moment.utc(date, 'YYYY-MM-DD').startOf('month').startOf('week').format('YYYY-MM-DD');
  }
}

export const getRangeEnd = (type, date = TODAY) => {
  if (type === CALENDAR_VIEWS.WEEK) {
    return moment.utc(date, 'YYYY-MM-DD').endOf('week').format('YYYY-MM-DD');
  } else if (type === CALENDAR_VIEWS.MONTH) {
    return moment.utc(date, 'YYYY-MM-DD').endOf('month').endOf('week').format('YYYY-MM-DD');
  }
}

export const DateNavigation = ({ size }) => {
  const record = useRecordContext()
  const translate = useTranslate();
  const { initialStart, timezone, type, navigateToDate, setNavigateToDate } = useCalendarContext();
  const { selectedGame } = useSchedulingContext();
  const { filterValues, setFilters } = useListContext();
  const [ initialDate, setInitialDate ] = useState({ reset: filterValues?.startTime == null });

  useEffect(() => {
    let date = initialStart || TODAY;

    if (selectedGame?.date) {
      date = selectedGame.date
    } else if (record?.startDate) {
      date = record.startDate
      // check if today's range overlaps with schedule range
      const startDate = getRangeStart(type, record.startDate);
      const endDate = getRangeEnd(type, record.startDate)

      const todaysRangeStart = getRangeStart(type)
      const todaysRangeEnd = getRangeEnd(type)
      if (createRange(todaysRangeStart, todaysRangeEnd).overlaps(createRange(startDate, record?.endDate || endDate))) {
        date = TODAY;
      }
    }

    setNavigateToDate(date)
  }, [ record?.startDate, record?.endDate, initialStart, timezone, type, selectedGame?.date, setNavigateToDate ])

  useEffect(() => {
    // initialize navigation date range
    if (navigateToDate) {
      const startTime = getRangeStart(type, navigateToDate);
      const endTime = getRangeEnd(type, navigateToDate);

      setFilters({
        startTime,
        endTime,
      })
      setNavigateToDate(null);
    }
  }, [ filterValues, setFilters, navigateToDate, setNavigateToDate, type ])

  useEffect(() => {
    // on unmount reset date if there wasn't a date set initially
    return () => {
      if (!initialDate.reset) return;
      const { _include } = filterValues;
      setFilters({ _include, startTime: undefined, endTime: undefined })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const { startTime, endTime } = filterValues;
    if (!startTime || !moment.utc(startTime).isValid()) return;

    if (moment.utc(startTime, 'YYYY-MM-DD').day() !== 0) {
      // is not start of week, set new range
      setFilters({
        ...filterValues,
        startTime: getRangeStart(type, startTime),
        endTime: getRangeEnd(type, startTime)
      })
      return;
    }

    if (!endTime) {
      setFilters({
        ...filterValues,
        endTime: getRangeEnd(type, startTime),
      })
      return;
    }
    if (record?.endDate && moment.utc(endTime, 'YYYY-MM-DD').isSame(record.endDate, 'day')) return;
    const diff = moment.utc(startTime, 'YYYY-MM-DD').diff(moment.utc(endTime, 'YYYY-MM-DD').format('YYYY-MM-DD'), 'days')
    if ((Math.abs(diff) + 1) % 7 === 0) return;

    setFilters({ ...filterValues, endTime: getRangeEnd(type, startTime) })
  }, [ record?.endDate, filterValues, setFilters, type ])

  const onToday = () => {
    const today = moment().format('YYYY-MM-DD')

    const startTime = getRangeStart(type, today)
    const endTime = getRangeEnd(type, today)

    setFilters({ ...filterValues, startTime, endTime })
  }

  const onBack = () => {
    const duration = type === CALENDAR_VIEWS.MONTH ? 'month' : 'week';

    let date = moment.utc(filterValues.startTime, 'YYYY-MM-DD')
    if (type === CALENDAR_VIEWS.MONTH) {
      date = date.add(1, 'week')
    }
    date = date.subtract(1, duration).format('YYYY-MM-DD')
    const startTime = getRangeStart(type, date)
    const endTime = getRangeEnd(type, date)
    setFilters({ ...filterValues, startTime, endTime })
  }

  const onNext = () => {
    const duration = type === CALENDAR_VIEWS.MONTH ? 'month' : 'week';

    let date = moment.utc(filterValues.startTime, 'YYYY-MM-DD')
    if (type === CALENDAR_VIEWS.MONTH) {
      date = date.add(1, 'week')
    }
    date = date.add(1, duration).format('YYYY-MM-DD')
    const startTime = getRangeStart(type, date)
    const endTime = getRangeEnd(type, date)
    setFilters({ ...filterValues, startTime, endTime })
  }

  const { startTime, endTime } = filterValues;
  const todayDisabled = startTime && endTime && moment.utc().isSameOrAfter(startTime, 'day') && moment.utc().isSameOrBefore(endTime, 'day')

  return <span>
    <Button variant="outlined" size={size} onClick={onToday} disabled={todayDisabled}>{translate('ra.date.lexical.today')}</Button>
    <IconButton size={size} onClick={onBack}><ChevronLeft /></IconButton>
    <IconButton size={size} onClick={onNext}><ChevronRight /></IconButton>
  </span>
}

const OffsetNavigation = ({ size }) => {
  const { columns, onNext, onBack, maxColumns, colOffset, totalColumns } = useCalendarContext();

  if (!totalColumns || columns.length >= totalColumns) return null;

  return <span>
    <IconButton size={size} onClick={onBack} disabled={colOffset === 0}><ChevronLeft /></IconButton>
    <IconButton size={size} onClick={onNext} disabled={(totalColumns - colOffset) <= maxColumns}><ChevronRight /></IconButton>
  </span>
}

export const CalendarNavigation = props => {
  const { type } = useCalendarContext();
  if ([CALENDAR_VIEWS.DAY, CALENDAR_VIEWS.SEASON].includes(type)) return <OffsetNavigation {...props} />;
  return <DateNavigation {...props} />
}
