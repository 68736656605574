import React from "react"
import { useRecordContext } from "react-admin"

import ListCard from "../../common/cards/ListCard"
import { isAuthorized } from "../../common/Authorize"

import { AddPoolButton } from "../pools/PoolModal"
import { PoolGrid } from "../pools/PoolGrid"
import { PoolIcon } from "../icons"

export default (props) => {
  const schedule = useRecordContext();

  const canAddPools = isAuthorized(schedule, 'pools', 'create');

  return <ListCard
    title="resources.pools.labels.card.title"
    icon={<PoolIcon />}
    reference="pools"
    target="scheduleId"
    sort={{
      field: 'name',
      order: 'ASC'
    }}
    addButton={canAddPools && <AddPoolButton initialValues={{ scheduleId: schedule?.id }} />}
    collapsable
    {...props}
  >
    <PoolGrid />
  </ListCard>
}
