import React from "react";
import { GET_LIST, useQuery, useTranslate } from "react-admin";
import { TextField, makeStyles, MenuItem } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useForm } from "react-final-form";

import { TeamSuggestion } from '../teams/TeamInput';
import { useScheduleStatsContext } from "./ScheduleStatsTable";

const inputProps = {
  variant: 'outlined',
  margin: 'dense',
}

const menuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
}

const useStyles = makeStyles(theme => ({
  input: {
    minWidth: theme.spacing(24),
  },
}));

const usePools = (poolIds) => useQuery({
  type: GET_LIST,
  resource: 'pools',
  payload: {
    filter: { id: { inq: poolIds } },
    pagination: { page: 1, perPage: 99999 },
  },
}, { enabled: poolIds?.length > 0 })

const useGroups = (groupIds) => useQuery({
  type: GET_LIST,
  resource: 'groups',
  payload: {
    filter: { id: { inq: groupIds } },
    pagination: { page: 1, perPage: 99999 },
  },
}, { enabled: groupIds?.length > 0 })

const useTeams = (teamIds) => useQuery({
  type: GET_LIST,
  resource: 'teams',
  payload: {
    filter: { id: { inq: teamIds } },
    pagination: { page: 1, perPage: 99999 },
  },
}, { enabled: teamIds?.length > 0 })

const NoOptionsText = (loading) => {
  const translate = useTranslate();
  return loading ? translate('ra.page.loading') : translate('ra.navigation.no_results')
}

export const StandingsTypeSelect = ({ handleStandingsType }) => {
  const { filters, setFilters, setSortBy, setSort, setPage, poolIds } = useScheduleStatsContext();
  const translate = useTranslate();
  const { reset } = useForm();
  const classes = useStyles();

  const handleTypeChange = (event) => {
    const { value } = event.target;

    if (value !== filters?.standingsType) {
      setSortBy('ranking');
      setSort('asc');
      reset();
    }

    setPage(0);
    setFilters(prev => ({ ...prev, poolId: null, standingsType: value }));
    handleStandingsType(value);
  };

  if (!poolIds?.length) return null;

  return <TextField
    className={classes.input}
    label={translate("components.scheduleStats.labels.standingsType")}
    select
    value={filters?.standingsType}
    onChange={handleTypeChange}
    SelectProps={{
      MenuProps: menuProps
    }}
    {...inputProps}
  >
    <MenuItem value="Overall">{translate("components.scheduleStats.values.standingsType.Overall")}</MenuItem>
    <MenuItem value="Pools">{translate("components.scheduleStats.values.standingsType.Pools")}</MenuItem>
  </TextField>
}

export const GroupSelect = () => {
  const { groupIds, filters, setFilters, setPage } = useScheduleStatsContext();
  const { data: groups, loading } = useGroups(groupIds);
  const translate = useTranslate();
  const classes = useStyles();

  const handleGroupChange = (event, value) => {
    setPage(0);
    setFilters(prev => ({ ...prev, groupId: value?.id }));
  };

  if (!groupIds?.length) return null;

  const value = groups?.find(group => group.id === filters?.groupId) || null;

  return <Autocomplete
    value={value}
    options={groups || []}
    getOptionLabel={(option) => option.name}
    noOptionsText={<NoOptionsText loading={loading} />}
    renderInput={(params) => <TextField {...params} className={classes.input} label={translate('resources.groups.name', 1)} {...inputProps} />}
    onChange={handleGroupChange}
  />
}

export const PoolSelect = () => {
  const { poolIds, filters, setFilters, setPage } = useScheduleStatsContext();
  const { data: pools } = usePools(poolIds);
  const translate = useTranslate();
  const classes = useStyles();

  const handlePoolChange = (event) => {
    const { value } = event.target;

    setPage(0);
    setFilters(prev => ({ ...prev, poolId: value?.id }));
  };

  if (!poolIds?.length || filters?.standingsType === 'Pools') return null;

  const value = pools?.find(pool => pool.id === filters?.poolId) || "";

  return <TextField
    className={classes.input}
    label={translate("resources.pools.name", 2)}
    select
    value={value}
    onChange={handlePoolChange}
    SelectProps={{
      MenuProps: menuProps
    }}
    {...inputProps}
  >
    {pools?.map(pool => <MenuItem key={pool.id} value={pool}>{pool.name}</MenuItem>)}
    {value && <MenuItem value={{ id: null }}><em>{translate('ra.action.clear')}</em></MenuItem>}
  </TextField>
}

export const ScheduleTeamSelect = () => {
  const { teamIds, filters, setFilters, setPage } = useScheduleStatsContext();
  const { data: scheduleTeams, loading } = useTeams(teamIds);
  const translate = useTranslate();
  const classes = useStyles();

  const handleTeamChange = (event, value) => {
    setPage(0);
    setFilters(prev => ({ ...prev, teamId: value?.id }));
  };

  const value = scheduleTeams?.find(team => team.id === filters?.teamId) || null;

  return <Autocomplete
    value={value}
    options={scheduleTeams || []}
    getOptionLabel={(option) => option.name}
    noOptionsText={<NoOptionsText loading={loading} />}
    renderOption={option => <TeamSuggestion suggestion={option} />}
    renderInput={(params) => <TextField {...params} className={classes.input} label={translate('resources.teams.name', 1)} {...inputProps} />}
    onChange={handleTeamChange}
  />
}

export const PlayerTypeSelect = () => {
  const { filters, setFilters, setSortBy, setSort, setPage } = useScheduleStatsContext();
  const translate = useTranslate();
  const classes = useStyles();

  const handleTypeChange = (event) => {
    const { value } = event.target;

    if (value !== filters?.type) {
      setSortBy('ranking');
      setSort('asc');
    }

    setPage(0);
    setFilters(prev => ({ ...prev, type: value }));
  };

  return <TextField
    className={classes.input}
    label={translate("components.scheduleStats.labels.type")}
    select
    value={filters?.type}
    onChange={handleTypeChange}
    SelectProps={{
      MenuProps: menuProps
    }}
    {...inputProps}
  >
    <MenuItem value="Skater">{translate("components.scheduleStats.values.type.Skater")}</MenuItem>
    <MenuItem value="Goalie">{translate("components.scheduleStats.values.type.Goalie")}</MenuItem>
  </TextField>
}
