import React, { useEffect, useRef, useState } from 'react';
import { BooleanInput, Button, GET_MANY_REFERENCE, GET_ONE, NumberInput, RecordContextProvider, SimpleForm, TextInput, useLocale, useQuery, useRecordContext, useTranslate } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import Iframe from 'react-iframe';
import { Grid, InputAdornment } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import createCalculator from 'final-form-calculate';
import { isEmpty } from 'lodash';

import { dedupe } from '@hisports/parsers';
import { translateApiProperty } from '@hisports/common';
import { GAME_OFFICE_TYPES } from '@hisports/common/src/constants';

import { apiClient, usePermissions } from '../../http';
import { formatCurrency, formatDistance } from '../../locale';

import Toolbar from '../../common/ra/Toolbar';
import CurrencyInput from '../../common/inputs/CurrencyInput';
import { ClaimStatusEnumInput, EnumInput } from '../../common/inputs/EnumInputs';
import { hasAnyScope } from '../../common/Authorize';

import { OfficeInput } from '../offices/OfficeInput';
import { ParticipantInput } from '../participants/ParticipantInput';
import { GameInput } from '../games/GameInput';
import OfficialClaimDocumentsCard from '../games/cards/OfficialClaimDocumentsCard';

import AddressAutoCompleteInput from '../addresses/AddressAutoCompleteInput';
import { SquareAlert } from '../../common/SquareAlert';

export const useGameOfficials = (gameId) => useQuery({
  type: GET_MANY_REFERENCE,
  resource: 'officials',
  payload: {
    target: 'games',
    id: gameId,
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'gameId', order: 'ASC' },
    filter: { participantId: { neq: null } },
  }
}, {
  enabled: gameId != null,
})

export const useGameAssignSettings = (gameId) => useQuery({
  type: GET_ONE,
  resource: 'gameAssignSettings',
  payload: {
    id: gameId,
  }
}, {
  enabled: gameId != null,
})

const getClaimType = (values) => {
  const claim = values?.claimSettings?.find(claimSettings => claimSettings.id === values.claimSettingId)
  return claim.type
}

const getClaimAmount = (values) => {
  if (!values) return 0;
  const claim = values?.claimSettings?.find(claimSettings => claimSettings.id === values.claimSettingId)
  if (claim.type === 'Travel') {
    if (!values.distance) return 0;
    return travelAmount(claim.rate, values.distance, claim.excludedDistance)
  }
  if (claim.type === 'Expense') {
    if (values.amount) return values.amount;
  }
  return claim.amount
}

const travelAmount = (amount, distance, exclude) => {
  if (!amount || !distance) return 0;
  if (exclude) {
    if (exclude >= distance) return 0;
    return amount * (distance - exclude);
  }
  return amount * distance
}

const travelCalculation = (rate, distance, exclude, locale) => {
  if (rate == null || distance == null) return null;
  if (exclude == null) return `${formatDistance(distance.toFixed(2))} x ${formatCurrency(rate, locale, true)}`;

  const nonExcluded = distance - exclude;
  return `(${formatDistance(nonExcluded < 0 ? distance.toFixed(2) : exclude.toFixed(2))} x ${formatCurrency(0, locale, true)}) + (${formatDistance(nonExcluded < 0 ? 0 : nonExcluded.toFixed(2))} x ${formatCurrency(rate, locale, true)})`
}

const getGameAddresses = async (gameId, participantId) => {
  if (!gameId || !participantId) return
  const addresses = await apiClient(`/games/${gameId}/getClaimAddresses?participantId=${participantId}`)
    .then(res => res?.data);
  return addresses
}

const getDistances = async (gameId, addressFrom, addressTo, intermediateStop) => {
  let url = `/games/${gameId}/distances?addressFrom=${addressFrom}&addressTo=${addressTo}`
  if (intermediateStop) {
    url += `&intermediateStop=${intermediateStop}`;
  }
  return apiClient(url)
    .then(res => res?.data);
}

const getDistanceMap = async (gameId, addressFrom, addressTo, intermediateStop) => {
  if (!addressFrom || !addressTo) return;
  let url = `/games/${gameId}/routeMap?addressFrom=${addressFrom}&addressTo=${addressTo}`
  if (intermediateStop) {
    url += `&intermediateStop=${intermediateStop}`;
  }
  return apiClient(url)
    .then(res => res?.data);
}

const getOfficeAssignSettings = officeId => {
  return apiClient('/effectiveOfficeAssignSettings', { params: { filter: { where: { officeId }, scope: 'Tenant' } } })
    .then(res => res?.data?.[0]);
}

const getOfficeClaimsSettings = officeId => {
  return apiClient('/officeClaimsSettings', { params: { filter: { where: { officeId }, scope: 'Authorized' } } })
    .then(res => res?.data);
}

const validate = (values, translate) => {
  const errors = {};

  const claim = values?.claimSettings?.find(claimSettings => claimSettings.id === values.claimSettingId)

  if (!values.participantId) errors.participantId = 'ra.validation.required'
  if (!values.gameId) errors.gameId = 'ra.validation.required'
  if (!values.officeId) errors.officeId = 'ra.validation.required'
  if (!values.type) errors.type = 'ra.validation.required'
  if (values.type && values.type ==='Travel' && !values.distance) errors.distance = 'ra.validation.required'
  if (values.type && values.type ==='Travel' && values.distance && values.distance < 0) errors.distance = 'ra.validation.positive';
  if (values.type && values.type ==='Travel' && !Number.isInteger(values.distance)) errors.distance = 'ra.validation.whole';
  if (values.amount == null) errors.amount = 'ra.validation.required';
  if (Number(values.amount).toFixed(2) > claim?.amount && values.type ==='Expense') errors.amount = translate('ra.validation.claim_maximum_amount', { amount: claim.amount });

  return errors;
}

const inputProps = {
  resource: 'officialClaims',
  basePath: '/officialclaims',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const useStyles = makeStyles(theme => ({
  frame: {
    width: '100%',
    height: theme.spacing(60),
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
  },
}))

const OfficeGameInput = ({ filter = {}, ...props }) => {
  const { values } = useFormState()
  const { officeId, participantId } = values || {};

  if (officeId) {
    filter = { and: [ filter, { or: [{ 'officials.officeId': officeId }, { 'assignSettings.officeId': officeId }] } ] }
  }

  if (participantId) {
    filter['officials.participantId'] = participantId;
  }

  return <GameInput filter={filter} {...props} />
}

const OfficialInput = ({ filter = {}, officials, ...props }) => {
  const { values } = useFormState()
  const { officeId } = values || {};

  if (officials) {
    let officeOfficials = officials.filter(official => official.officeId === officeId)
    if (!officeOfficials.length) {
      officeOfficials = officials.filter(official => official.officeId === null)
    }
    filter.id = { inq: officeOfficials.map(official => official.participantId) }
  }

  return <ParticipantInput filter={filter} {...props} />
}

const DistanceInput = props => {
  const translate = useTranslate();
  return <NumberInput {...props} InputProps={{
    endAdornment: <InputAdornment position="end">
      {translate('ra.input.adornments.kilometers')}
    </InputAdornment>
  }} />
}

const FormContent = (props) => {
  const { values, initialValues } = useFormState()
  const { gameId, officeId, type, claimSettingId, amount, distance } = values || {};
  const [ officeAssignSettings, setOfficeAssignSettings ] = useState();
  const [ officeClaimsSettings, setOfficeClaimsSettings ] = useState();
  const [ gameClaimAddresses, setGameClaimAddresses ] = useState();
  const form = useForm();
  const { data: gameAssignSettings } = useGameAssignSettings(gameId);
  const { data: officials } = useGameOfficials(gameId);
  const permissions = usePermissions();
  const translate = useTranslate();
  const claim = useRecordContext();
  const locale = useLocale();
  const classes = useStyles();

  const handleCalculateTravel = async () => {
    try {
      const distance = await getDistances(gameId, values.addressFrom, values.addressTo, values.intermediateStop);
      if (distance !== null) {
        form.change('distance', values.roundtrip ? distance * 2 : distance);
      }
    } catch (error) {
      <SquareAlert severity="error" title="Error" />
    }
  };

  useEffect(() => {
    if (!officeId) return
    getOfficeAssignSettings(officeId)
      .then(settings => setOfficeAssignSettings(settings))
    getOfficeClaimsSettings(officeId)
      .then(settings => {
        setOfficeClaimsSettings(settings)
        values.claimSettings = settings
      })
  }, [officeId, values])

  useEffect(() => {
    if (claim?.id) return
    getGameAddresses(values.gameId, values.participantId)
      .then(addresses => setGameClaimAddresses(addresses))
  }, [claim, values])

  const officeIds = dedupe(officials?.map(official => official.officeId || gameAssignSettings?.officeId) || []);
  const isAssigner = permissions && permissions.some(({ roleType, scopes = [], officeIds = [], inherited }) =>
    (roleType === 'System') ||
    (roleType === 'Office' && inherited === false && hasAnyScope(scopes, ['assigning:manage', 'assigning:assign']) && officeIds.includes(officeId))
  )

  const showDistance = values?.type === 'Travel';
  const showStatus = isAssigner && claim?.id;
  const showIntermediateTrip = values?.hasIntermediateStop
  const activateCalculateTravel = values?.addressFrom && values?.addressTo
  const activateCustomAmount = values?.type === 'Expense'
  const isApproved = initialValues?.status === 'Approved'
  const disableAutocompleteAddressInputs = isApproved || !!initialValues?.addressFrom || !!initialValues?.addressTo || !!initialValues?.intermediateStop || claim?.id
  const inputSize = showDistance ? 4 : 6;

  const amountHelperText = (() => {
    if (!officeClaimsSettings || !claimSettingId) return;
    const claim = officeClaimsSettings.find(claimSettings => claimSettings.id === claimSettingId)

    if (claim.type === 'Travel') {
      if (!distance) return;
      return travelCalculation(claim.rate, distance, claim.excludedDistance, locale);
    }

    if (claim.type === 'Per Diem') {
      return `Allowed Amount: ${formatCurrency(claim.amount, locale)}`;
    }

    if (claim.type === 'Expense') {
      return `Maximum Amount: ${formatCurrency(claim.amount, locale)}`;
    }
  })()

  const distanceHelperText = (() => {
    if (!officeClaimsSettings || !showDistance) return null;
    const claim = officeClaimsSettings.find(claimSettings => claimSettings.id === claimSettingId)

    if (claim.type === 'Travel' && claim.excludedDistance != null) {
      return translate('resources.officialClaims.helpers.distance', claim.excludedDistance)
    }
  })()

  return <Grid container spacing={2} fullWidth>
    <Grid item xs={12} sm={4}>
      <OfficeGameInput source="gameId" disabled={!!initialValues?.gameId || isApproved} {...inputProps} />
    </Grid>
    <Grid item xs={12} sm={4}>
      <OfficeInput source="officeId" disabled={!!initialValues?.officeId || !gameId || isApproved} filter={{ _scope: 'Tenant', id: { inq: officeIds }, type: { nin: [...GAME_OFFICE_TYPES, 'Historical'] } }} {...inputProps} />
    </Grid>
    <Grid item xs={12} sm={4}>
      <OfficialInput source="participantId" disabled={!!initialValues?.participantId || !gameId || !officeId || !officials || isApproved}
        filter={{ _scope: 'Tenant' }} officials={officials} {...inputProps} />
    </Grid>
    <Grid item xs={12} sm={inputSize}>
      <EnumInput source="claimSettingId" emptyOptionsText="resources.officeClaimsSettings.helpers.no_options" choices={officeClaimsSettings} disabled={!!initialValues.claimSettingId || !officeId} optionText={setting => translateApiProperty(setting, 'name', locale)} {...inputProps} />
    </Grid>
    <Grid item xs={12} sm={inputSize}>
      <TextInput source="type" disabled {...inputProps} />
    </Grid>
    {showDistance && <Grid item xs={12} sm={inputSize}>
      <DistanceInput min={0} source="distance" disabled helperText={distanceHelperText} {...inputProps} />
    </Grid>}
    {showDistance && <Grid item xs={12} sm={showIntermediateTrip ? 4 : 6}>
      <AddressAutoCompleteInput source="addressFrom" label={translate("resources.officialClaims.fields.addressFrom")} disabled={disableAutocompleteAddressInputs} gameId={gameId}
        defaultAddress={initialValues?.addressFrom || gameClaimAddresses?.officialAddress} {...inputProps} />
    </Grid>}
    {(showDistance && showIntermediateTrip) && <Grid item xs={12} sm={showIntermediateTrip ? 4 : 6}>
      <AddressAutoCompleteInput source="intermediateStop" defaultAddress={initialValues?.intermediateStop} label={translate("resources.officialClaims.fields.intermediateStop")} disabled={disableAutocompleteAddressInputs}
        gameId={gameId} {...inputProps} />
    </Grid>}
    {showDistance && <Grid item xs={12} sm={showIntermediateTrip ? 4 : 6}>
      <AddressAutoCompleteInput source="addressTo" label={translate("resources.officialClaims.fields.addressTo")} disabled={disableAutocompleteAddressInputs}
        defaultAddress={initialValues?.addressTo || gameClaimAddresses?.venueAddress} gameId={gameId} {...inputProps} />
    </Grid>}
    {values.claimSettingId && <Grid item xs={12}>
      <CurrencyInput source="amount" disabled={!activateCustomAmount || !isAssigner} helperText={amountHelperText} {...inputProps} />
    </Grid>}
    {showDistance && <Grid item xs={12} sm={inputSize}>
      <BooleanInput source="roundtrip" disabled={isApproved} helperText={false} {...inputProps} />
    </Grid>}
    {showDistance && <Grid item xs={12} sm={inputSize}>
      <BooleanInput source="hasIntermediateStop" disabled={disableAutocompleteAddressInputs} defaultValue={values?.intermediateStop} helperText={false} {...inputProps} />
    </Grid>}
    {showDistance && <Grid item xs={12} sm={inputSize}>
      <Button source="calculateDistance" label="resources.officialClaims.fields.calculateDistance"
        disabled={isApproved || !activateCalculateTravel || !!initialValues?.addressFrom} onClick={() => handleCalculateTravel()} size="medium" {...inputProps} />
    </Grid>}
    {values.mapUrl && <Grid item xs={12}>
      <Iframe source="mapUrl" url={values.mapUrl} className={classes.frame} />
    </Grid>}
    {showStatus && <Grid item xs={12}>
      <ClaimStatusEnumInput source="status" disabled={!gameId} radio {...inputProps} />
    </Grid>}
    <Grid item xs={12}>
      <TextInput source="note" disabled={!gameId || isApproved} helperText="ra.message.optional" multiline minRows={3} {...inputProps} />
    </Grid>
    <RecordContextProvider value={{ id: gameId, claimId: initialValues?.id }}>
      <Grid item xs={12}>
        <OfficialClaimDocumentsCard />
      </Grid>
    </RecordContextProvider>
  </Grid>
}

export const ClaimForm = ({ initialValues = {}, ...props }) => {
  const translate = useTranslate();

  const decorators = useRef([createCalculator({
    field: 'claimSettingId',
    updates: {
      amount: async (claimSettingId, values, prevValues) => {
        if (!claimSettingId || !values?.officeId) return 0;
        if (!values.claimSettings) values.claimSettings = await getOfficeClaimsSettings(values.officeId)
        return getClaimAmount(values)
      },
      type: async (claimSettingId, values, prevValues) => {
        if (!claimSettingId || !values?.officeId) return;
        if (!values.claimSettings) values.claimSettings = await getOfficeClaimsSettings(values.officeId)
        return getClaimType(values)
      }
    }
  }, {
    field: 'hasIntermediateStop',
    updates: {
      intermediateStop: async (hasIntermediateStop, values, prevValues) => {
        if (isEmpty(prevValues)) return values.intermediateStop
        if (values.hasIntermediateStop == false) values.intermediateStop = ""
        return values.intermediateStop
      },
    } }, {
    field: 'roundtrip',
    updates: {
      distance: async (roundtrip, values, prevValues) => {
        if (!values?.distance) return;
        if (isEmpty(prevValues)) return values.distance
        if (values.roundtrip) return values.distance * 2
        return values.distance / 2
      },
    } }, {
    field: 'distance',
    updates: {
      amount: async (distance, values, prevValues) => {
        if (!distance || !values?.officeId) return 0;
        if (!values.claimSettings) values.claimSettings = await getOfficeClaimsSettings(values.officeId)
        return getClaimAmount(values)
      },
      mapUrl: async (mapUrl, values, prevValues) => {
        if (isEmpty(prevValues)) return values.mapUrl
        if (values?.addressFrom && values?.addressTo) return getDistanceMap(values.gameId, values.addressFrom, values.addressTo, values.intermediateStop)
        return values.mapUrl
      }
    },
  })])

  return <SimpleForm toolbar={<Toolbar />} validate={values => validate(values, translate)} initialValues={{ status: 'Pending', ...initialValues }} decorators={decorators.current} {...props}>
    <FormContent {...props} />
  </SimpleForm>
}
