import React from 'react';
import { EditButton, ShowButton, useEditContext, useResourceContext } from 'react-admin';
import { ActionMenu } from '../../common/ActionMenu';
import { isAuthorized } from '../../common/Authorize';

import { TopToolbar } from '../../common/cards/Card';

import OfficeInternalActions from './OfficeInternalActions';

import { usePermissions } from '../../http';

export const OfficeActions = ({ hasEdit, hasShow, ...props }) => {
  const resource = useResourceContext(props)
  const { basePath, record: office } = useEditContext(props);
  const permissions = usePermissions();

  const canEditResource = isAuthorized(office, resource, 'edit');
  const canShowResource = isAuthorized(office, resource, 'show');
  const isSystem = permissions.some(p => p.roleType === 'System');

  return <TopToolbar>
    {hasEdit && canEditResource && <EditButton basePath={basePath} resource={resource} record={office} />}
    {hasShow && canShowResource && <ShowButton basePath={basePath} resource={resource} record={office} />}
    {isSystem && <ActionMenu>
      <OfficeInternalActions />
    </ActionMenu>}
  </TopToolbar>
}
