import React from 'react';
import { SimpleForm, useTranslate } from 'react-admin';

import { FF_POOLS } from '@hisports/common/featureFlags';

import { EditResourceDialog } from '../../common/dialogs/ResourceDialog';
import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { useSeason } from '../../common/inputs/SeasonSelector';
import { useFlag } from '../../http';

import { TeamInput } from '../teams/TeamInput';

import { ScheduleGroupInput } from '../groups/GroupInput';
import { PoolInput } from '../pools/PoolInput';

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const EditTeamForm = props => {
  const seasonId = useSeason();
  const translate = useTranslate();
  const isEnabled = useFlag();

  return <SimpleForm {...props}>
    <TeamInput source="teamId" filter={{ seasonId, _scope: 'Tenant' }} {...inputProps} ignoreSchedule />
    <ScheduleGroupInput source="groupId" showNone={translate('ra.message.no_group')} {...inputProps} />
    {isEnabled(FF_POOLS) && <PoolInput source="poolId" {...inputProps} />}
  </SimpleForm>
}

const EditTeamDialog = props =>
  <EditResourceDialog label="resources.scheduleteams.labels.edit" resource="scheduleteams" basePath="/scheduleteams" undoableDelete={false} {...props}>
    <EditTeamForm />
  </EditResourceDialog>

export const ScheduleTeamGrid = props => <DialogDatagrid dialog={EditTeamDialog} {...props} />
