import React from 'react';
import { SimpleList, useListContext } from 'react-admin';

import { SMAChip } from '../../common/chips/SMAChip';

const AccountRow = ({ account }) => <>
  {account.email}
  {account?.externalId && <SMAChip />}
</>

export const AccountGrid = props => {
  const { data, ids } = useListContext(props);
  if (!ids || !data) return null;

  return <SimpleList
    primaryText={account => <AccountRow account={account} />}
    linkType="show"
    {...props} />
}
