import React from 'react'
import { RecordContextProvider, useRecordContext } from 'react-admin';
import { makeStyles, TableCell } from '@material-ui/core'

import InfoCard from '../../../common/cards/InfoCard';
import { useSport } from '../../../http';

import { TeamField } from '../../teams/TeamField';
import { ParticipantField } from '../../participants/ParticipantField';
import { ScheduleStatsTable } from '../ScheduleStatsTable'
import { GroupSelect, PlayerTypeSelect, ScheduleTeamSelect } from '../ScheduleStatsTableFilters';

const useStyles = makeStyles(theme => ({
  input: {
    minWidth: theme.spacing(24),
  },
  teamField: {
    display: 'block',
    color: theme.palette.text.secondary,
  }
}));

const renderColumns = (classes) => (row, schedule) => {
  const { participantId, teamId } = row;

  return <TableCell component="th" scope="row" >
    <RecordContextProvider value={{ participantId, teamId, ...schedule }}>
      <ParticipantField source="participantId" />
      <TeamField className={classes.teamField} source="teamId" variant="caption" link={false} />
    </RecordContextProvider>
  </TableCell>
}

const renderHeaders = ({ translate }) =>
  <TableCell component="th" scope="row" style={{ width: 250 }}>{translate('resources.participants.labels.card.title')}</TableCell>

export default function PlayerScheduleStatsCard() {
  const schedule = useRecordContext();
  const sport = useSport();
  const classes = useStyles();

  const isHockey = sport === 'Hockey';

  const filters = [
    isHockey && <PlayerTypeSelect key="player-type" />,
    <ScheduleTeamSelect key="team" />,
    <GroupSelect key="group" />,
  ].filter(Boolean);

  return <InfoCard
    title="components.scheduleStats.titles.ParticipantScheduleStats"
  >
    <ScheduleStatsTable
      resource="ParticipantScheduleStats"
      filters={filters}
      filter={{ type: 'Skater' }}
      sort={{ field: 'ranking', order: 'asc' }}
      renderColumns={renderColumns(classes)}
      renderHeaders={renderHeaders}
      schedule={schedule}
    />
  </InfoCard>
}
