import React from 'react';
import { EditButton, ShowButton, useEditContext, useResourceContext } from 'react-admin';

import { usePermissions } from '../../http';
import { isAuthorized } from '../../common/Authorize';
import { TopToolbar } from '../../common/cards/Card';
import { ActionMenu } from '../../common/ActionMenu';

import { AnalyzeButton } from './AnalyzeDialog';
import ScheduleInternalActions from './ScheduleInternalActions';

export const ScheduleActions = ({ hasEdit, hasShow, ...props }) => {
  const resource = useResourceContext(props);
  const { basePath, record: schedule } = useEditContext(props);
  const permissions = usePermissions()

  if (!schedule) return null;
  const { id: scheduleId, officeId, type } = schedule;
  const isSystem = permissions.some(p => p.roleType === 'System')

  const canEditResource = isAuthorized(schedule, resource, 'edit');
  const canShowResource = isAuthorized(schedule, resource, 'show');
  const showInternalActions = isSystem

  return <TopToolbar>
    {hasEdit && canEditResource && <EditButton basePath={basePath} resource={resource} record={schedule} />}
    {hasShow && canShowResource && <ShowButton basePath={basePath} resource={resource} record={schedule} />}
    <AnalyzeButton scheduleId={scheduleId} officeId={officeId} />
    {showInternalActions && <ActionMenu>
      <ScheduleInternalActions />
    </ActionMenu>}
  </TopToolbar>
}
