import React, { useState } from 'react';
import { useDataProvider, useRefresh, useNotify, useTranslate, useRecordContext } from 'react-admin';
import { Button } from '@material-ui/core';
import { PlaylistAdd } from '@material-ui/icons';

import { TeamImportDialog } from '../schedules/dialogs/TeamImportDialog';

const getMaxOrder = teams => teams.length ? Math.max(...teams.map(team => team.order)) : 0;

export const TeamImportButton = ({ initialValues, size, variant }) => {
  const [ isOpen, setOpen ] = useState(false);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const schedule = useRecordContext();

  const onSubmit = async ({ teamIds, groupId, poolId }) => {
    if (!teamIds || !teamIds.length) {
      setOpen(false);
      return;
    }

    const scheduleTeams = await dataProvider.getList("scheduleteams", { filter: { scheduleId: schedule.id, groupId } })
    let maxOrder = getMaxOrder(scheduleTeams.data)
    const teams = teamIds.map(teamId => {
      if (!scheduleTeams.data.some(team => team.teamId == teamId)) maxOrder++;
      const team = ({ scheduleId: schedule.id, groupId, teamId, order: maxOrder, poolId })
      return team
    })

    return Promise.all(teams.map(
      data => dataProvider.create("scheduleteams", { data })
    )).then(() => {
      setOpen(false)
      refresh()
    }).catch(error => {
      notify(error.message, 'error');
    })
  }

  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<PlaylistAdd fontSize="small" />} onClick={() => setOpen(true)}>
      {translate('resources.scheduleteams.labels.import_teams')}
    </Button>
    <TeamImportDialog
      title={translate('resources.scheduleteams.labels.import_teams')}
      isOpen={isOpen}
      onSubmit={onSubmit}
      onClose={() => setOpen(false)}
      initialValues={initialValues}
      scheduleIdForGroupInput={schedule.id}
    />
  </>
}
