import React from "react";
import { useTranslate } from "react-admin";

import AutocompleteInput from "./AutocompleteInput";
import ChipInput from "./ChipInput";

const ChipSelectInput = ({ multiple, optionText, choices, showNone, emptyText, ...props }) => {
  const translate = useTranslate();

  let data = choices ? [...choices] : [];
  data = data.sort((dataA, dataB) => {
    const a = optionText(dataA);
    const b = optionText(dataB);

    if (!a && !b) return 0;
    if (a && !b) return -1;
    if (b && !a) return 1;

    return a.localeCompare(b, undefined, {
      sensitivity: 'base',
      ignorePunctuation: true,
      numeric: true,
    })
  })

  const long = data.length > 15;
  if (long && showNone) data.push({ id: null, name: showNone });

  if (multiple || long) return <AutocompleteInput choices={data} optionText={optionText} emptyText={emptyText && translate(emptyText)} multiple={multiple} nullable {...props} />
  return <ChipInput choices={data} optionText={optionText} {...props} />
}

export default ChipSelectInput;
