import React from 'react';
import { TextInput, NullableBooleanInput, BooleanInput } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import { GAME_OFFICE_TYPES } from '@hisports/common/src/constants';
import { LATEST_SEASON } from '@hisports/common/src/seasons';

import { ResetFiltersButton } from '../../common/ResetFiltersButton';
import { InlineDateInput } from '../../common/inputs/DateInput';
import { GameStatusEnumInput } from '../../common/inputs/EnumInputs';
import { SeasonFilter, useSeason } from '../../common/inputs/SeasonSelector';
import { isAuthorized } from '../../common/Authorize';
import { FilterCard } from '../../common/cards/FilterCard';
import { SavedSearches } from '../../common/SavedSearches';
import { useDefaultSeason, useScopes } from '../../http';
import LazyLoadFilter from '../../common/ra/Filter';

import { OfficeInput } from '../offices/OfficeInput';
import { SurfaceInput } from '../surfaces/SurfaceInput';
import { TeamInput } from '../teams/TeamInput';
import { EventViewSettingsForm, useShowAssignments } from '../events/EventViewSettings';
import { CategoryInput } from '../categories/CategoryInput';
import { ScheduleInput } from '../schedules/ScheduleInput';
import { QuickAssignListInput } from '../lists/QuickAssignListInput';

const useStyles = makeStyles(theme => ({
  filters: {
    marginTop: theme.spacing(1)
  }
}))

const filterProps = {
  fullWidth: true,
}

const multipleProps = {
  multiple: true,
  allowEmpty: false,
  ...filterProps,
}

const allOfficeFilter = {
  type: { neq: 'Historical' }
}

const localOfficeFilter = {
  type: { nin: [...GAME_OFFICE_TYPES, 'Historical'] }
}

const GameFilters = props => {
  const scopes = useScopes();
  const seasonId = useSeason();
  const defaultSeason = useDefaultSeason();
  const [ showAssignments ] = useShowAssignments();

  const canListOffices = isAuthorized(scopes, 'offices', 'show');
  const canAssign = isAuthorized(scopes, 'games', 'assign');
  const canListTeams = isAuthorized(scopes, 'teams', 'list');
  const canListSurfaces = isAuthorized(scopes, 'surfaces', 'list');
  const canListSchedules = isAuthorized(scopes, 'schedules', 'list');
  const showPastSeason = (seasonId === LATEST_SEASON) || (seasonId === defaultSeason);

  return <LazyLoadFilter variant="outlined" margin="dense" fullWidth {...props}>
    <TextInput source="number" alwaysOn {...filterProps} />
    <InlineDateInput source="startTime" label="resources.games.filters.startTime" alwaysOn {...filterProps} />
    <InlineDateInput source="endTime" label="resources.games.filters.endTime" alwaysOn {...filterProps} />
    {canListOffices && <OfficeInput source="officeId" filter={allOfficeFilter} {...multipleProps} />}
    {canListOffices && <OfficeInput source="scheduleOffices" label="resources.games.filters.scheduleOffices" filter={allOfficeFilter} alwaysOn {...multipleProps} />}
    {canListOffices && <OfficeInput source="teamOffices" label="resources.games.filters.teamOffices" filter={localOfficeFilter} alwaysOn {...multipleProps} />}
    {canListOffices && <OfficeInput source="homeTeamOffices" label="resources.games.filters.homeTeamOffices" filter={localOfficeFilter} {...multipleProps} />}
    {canListOffices && <OfficeInput source="awayTeamOffices" label="resources.games.filters.awayTeamOffices" filter={localOfficeFilter} {...multipleProps} />}
    {canListOffices && <OfficeInput source="surfaceOffices" label="resources.games.filters.surfaceOffices" filter={localOfficeFilter} {...multipleProps} />}
    {canAssign && <OfficeInput source="assignOffices" label="resources.games.filters.assignOffices" filter={{ ...localOfficeFilter, isAssigner: true }} alwaysOn {...multipleProps} />}
    {canAssign && showAssignments && <QuickAssignListInput alwaysOn {...filterProps} />}
    {canListSurfaces && <SurfaceInput source="effectiveSurfaces" filter={{ parentId: null }} alwaysOn {...multipleProps} />}
    {canListTeams && <TeamInput source="teamId" label="resources.teams.fields.teamId" filter={{ seasonId }} alwaysOn {...multipleProps} />}
    {canListSchedules && <ScheduleInput source="scheduleId" {...multipleProps} />}
    <CategoryInput source="categoryId" alwaysOn {...multipleProps} />
    <GameStatusEnumInput source="status" alwaysOn {...multipleProps} />
    <NullableBooleanInput source="isApproved" label="resources.games.filters.isApproved" alwaysOn {...filterProps} />
    <NullableBooleanInput source="isCertified" label="resources.games.filters.isCertified" alwaysOn {...filterProps} />
    {showPastSeason && <BooleanInput source="pastEvents" label="resources.games.filters.pastEvents" alwaysOn {...filterProps} />}
    <BooleanInput source="bracketGamesOnly" label="resources.games.filters.bracketGamesOnly" />
    <SeasonFilter source="seasonId" alwaysOn {...filterProps} />
  </LazyLoadFilter>
}

export const GameFilterList = ({ disableAssignments }) => {
  const classes = useStyles();
  return <FilterCard>
    <GameFilters />
    <GameFilters className={classes.filters} context="button" />
    <ResetFiltersButton />

    <EventViewSettingsForm disableAssignments={disableAssignments} disableCalendar showLabel />
    <SavedSearches ignoredFilters={['seasonId', 'pastEvents']} />
  </FilterCard>
}
